<template>
  <div class="mx-2 report">
    <div class="container noPrint">
      <h1 class="my-3" v-if="!userIdProp && !hcaiForm && !ohipForm">
        Billing Reports
      </h1>
      <div class="row filter pt-3">
        <div class="col-12">
          <div>
            <div class="row" v-if="!userIdProp">
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0">Clients</label>
                <alert
                  v-if="usersWithRolesLoading"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <multiselect
                  v-if="!usersWithRolesLoading"
                  v-model="select.client"
                  :options="sortAlphabetically(clientsList)"
                  :multiple="false"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="All Clients"
                  Value="user_id"
                  label="name"
                  track-by="user_id"
                  class="app-select"
                  :preselect-first="false"
                  @input="updateSelectedClient()"
                >
                  <template #noResult>
                    <div class="no-result">Oops! No clients found.</div>
                  </template>
                </multiselect>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0"
                  >Clients Administrator</label
                >
                <alert
                  v-if="usersWithRolesLoading"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <multiselect
                  v-if="!usersWithRolesLoading"
                  v-model="select.admin"
                  :options="sortAlphabetically(clientsAdmins)"
                  :multiple="false"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="All Administrators"
                  Value="user_id"
                  label="name"
                  track-by="user_id"
                  class="app-select"
                  :preselect-first="false"
                  @input="updateSelectedAdmin()"
                >
                  <template #noResult>
                    <div class="no-result">Oops! No users found.</div>
                  </template>
                </multiselect>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0">Class</label>
                <alert
                  v-if="gettingClasses"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <div class="form-group class-ddl mb-0" v-if="!gettingClasses">
                  <multiselect
                    v-model="filter.classification"
                    :options="classifications"
                    @input="markeFilterAsDirty()"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Classes"
                    Value="id"
                    label="subject"
                    track-by="id"
                    class="app-select"
                    :preselect-first="false"
                  >
                    <template #noResult>
                      <div class="no-result">Oops! No classes found.</div>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div
                class="col-12 col-md-6 col-lg-4 mb-3"
                v-if="authUser && (authUser.isAdmin || authUser.isManaginAdmin)"
              >
                <label class="col-12 font-weight-bold pt-2 pl-0">Clinic</label>
                <alert
                  v-if="gettingClinics"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <div class="form-group class-ddl mb-0" v-if="!gettingClinics">
                  <multiselect
                    v-model="filter.clinic"
                    :options="clinics"
                    @input="markeFilterAsDirty()"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Clinics"
                    Value="id"
                    label="name"
                    track-by="id"
                    class="app-select"
                    :preselect-first="false"
                  >
                    <template #noResult>
                      <div class="no-result">Oops! No clinics found.</div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0"
                  >Profession</label
                >
                <alert
                  v-if="gettingProfessions"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <div
                  class="form-group profession-ddl mb-0"
                  v-if="!gettingProfessions"
                >
                  <multiselect
                    v-model="filter.professions"
                    :options="professions"
                    @input="markeFilterAsDirty(), (filter.service = '')"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Professions"
                    Value="id"
                    label="name"
                    track-by="id"
                    class="app-select"
                    :preselect-first="false"
                  >
                    <template #noResult>
                      <div class="no-result">Oops! No professions found.</div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="col-12 col-md-6 col-lg-4 mb-3">
                <label class="col-12 font-weight-bold pt-2 pl-0">Service</label>
                <alert
                  v-if="gettingServices"
                  class="py-8-px light-shadow mb-0"
                ></alert>
                <div
                  class="form-group service-ddl mb-0"
                  v-if="!gettingServices"
                >
                  <multiselect
                    v-model="filter.service"
                    :options="mainServices"
                    @input="markeFilterAsDirty(), (filter.professions = '')"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="All Services"
                    Value="id"
                    label="name"
                    track-by="id"
                    class="app-select"
                    :preselect-first="false"
                  >
                    <template #noResult>
                      <div class="no-result">Oops! No services found.</div>
                    </template>
                  </multiselect>
                </div>
              </div>
            </div>

            <div class="border-bottom align-items-center">
              <label class="col-12 col-md-1 font-weight-bold mb-0 pl-0"
                >Date</label
              >
              <div class="mt-2 pt-1 col-12 col-md-6 pl-0">
                <v-date-picker
                  v-show="filter.dateRangeEnabled"
                  v-model="filter.range"
                  mode="date"
                  :masks="masks"
                  is-range
                  @input="markeFilterAsDirty()"
                >
                  <template v-slot="{ inputValue, inputEvents, isDragging }">
                    <div
                      class="
                        d-flex
                        justify-content-start
                        align-items-center
                        form-group
                        mb-4
                      "
                    >
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <div class="mx-2 mx-md-4">-</div>
                      <input
                        class="px-2 py-1 form-control light-shadow"
                        :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>

            <br />
            <div class="row border-bottom">
              <div class="col-12 mt-2 pt-1 row mx-0">
                <select-control
                  v-model="uiFilter.optional_columns.group_by_criteria"
                  :options="
                    userIdProp
                      ? uiFilter.nonMainGroupByOptions
                      : uiFilter.groupByOptions
                  "
                  type="horizontal"
                  @input="shouldViewReport && getReportByFilterHelper()"
                  class="group-by-select"
                  WrapClass="col-auto"
                  labelClass="d-flex align-items-center"
                  >Show and group by</select-control
                >
                <select-control
                  v-model="filter.non_billable"
                  :options="uiFilter.billableOptions"
                  @input="markeFilterAsDirty()"
                  type="horizontal"
                  class="group-by-select ml-5"
                  WrapClass="col-auto"
                  labelClass="d-flex align-items-center"
                  >Session Type</select-control
                >
              </div>

              <div class="col-11 d-flex flex-wrap mt-2 py-4 row mx-0">
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="apply_pagination"
                    v-model="filter.pagination"
                    @input="
                      markeFilterAsDirty(),
                        (uiFilter.optional_columns.automaticLoading =
                          !filter.pagination)
                    "
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="apply_pagination"
                    >Use Pagination</label
                  >
                </div>
                <div
                  class="custom-control custom-checkbox mr-md-5"
                  v-if="filter.pagination"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="automatic_loading"
                    v-model="uiFilter.optional_columns.automaticLoading"
                    @input="markeFilterAsDirty()"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="automatic_loading"
                    >Auto Loading</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_mileage"
                    v-model="uiFilter.optional_columns.mileage"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_mileage"
                    >View QTY as hours, flat, and mileage</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_user_id"
                    v-model="uiFilter.optional_columns.userId"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_user_id"
                    >Show Client ID</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_amt"
                    v-model="uiFilter.optional_columns.amt"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_amt"
                    >Show Amount</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_measure"
                    v-model="uiFilter.optional_columns.unit"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_measure"
                    >Show Measure</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_tax"
                    v-model="uiFilter.optional_columns.tax"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_tax"
                    >Show Tax</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_interest"
                    v-model="uiFilter.optional_columns.interest"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_interest"
                    >Show Interest</label
                  >
                </div>
                <div class="custom-control custom-checkbox mr-md-5">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_sub"
                    v-model="uiFilter.optional_columns.subTotal"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="show_sub"
                    >Show Subtotal</label
                  >
                </div>
                <div
                  class="custom-control custom-checkbox mr-md-5"
                  v-if="!hcaiForm"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="hide_hcai"
                    v-model="hideHcai"
                    @input="markeFilterAsDirty()"
                  />
                  <label class="custom-control-label mr-3 mb-3" for="hide_hcai"
                    >Hide HCAI</label
                  >
                </div>
                <div class="custom-control custom-checkbox" v-if="false">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="show_hcai_format"
                    v-model="filter.hcaiFormat"
                    @change="shouldViewReport && getReportByFilterHelper()"
                  />
                  <label
                    class="custom-control-label mr-3 mb-3"
                    for="show_hcai_format"
                    >HCAI Format</label
                  >
                </div>
              </div>
            </div>

            <div
              class="
                my-4
                d-flex
                justify-content-between
                flex-wrap
                report-actions-container
              "
            >
              <div
                class="
                  d-flex
                  flex-wrap
                  align-items-center
                  report-actions-inner-container
                "
              >
                <button
                  class="
                    mr-3
                    btn btn-black
                    text-uppercase
                    view-report
                    position-relative
                  "
                  :saving="saving"
                  :disabled="reportsLoading || busy"
                  @click="
                    (reportIsPending = reportIsPending || busy),
                      (isCancelled = isCancelled || busy),
                      getReportByFilterHelper()
                  "
                >
                  View Report
                  <div
                    class="action-indicator btn btn-danger spinner-grow"
                    v-if="filter.filterIsDirty"
                  ></div>
                </button>
                <div class="d-flex mr-3" :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Billing-Report"
                    worksheet="Billing Report"
                    :fields="excel_fields"
                    :before-generate="generateExcelOnly"
                    default-value=""
                    header="Billing Report"
                  >
                    <button
                      class="
                        btn btn-deep-success
                        text-uppercase
                        position-relative
                      "
                      :disabled="reportsLoading || busy"
                    >
                      <i class="far fa-file-excel fa-lg"></i> Generate Excel
                      Only
                      <div
                        class="action-indicator btn btn-danger spinner-grow"
                        v-if="filter.filterIsDirty"
                      ></div>
                    </button>
                  </JsonExcel>
                  <div
                    class="btn-group excel-control-group"
                    :class="`${
                      uiFilter.optional_columns.group_by_criteria != 0
                        ? ''
                        : 'transparent'
                    }`"
                  >
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu excel-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Excel settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            :key="printSettings.exportTotalOnly"
                            type="checkbox"
                            class="custom-control-input"
                            id="show_total_only_in_report"
                            v-model="printSettings.exportTotalOnly"
                            @change="
                              shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_total_only_in_report"
                            >Export Total Only</label
                          >
                        </div>
                      </div>
                      <div
                        :class="`dropdown-item ${
                          printSettings.exportTotalOnly ? '' : 'disabled'
                        }`"
                        v-if="uiFilter.optional_columns.group_by_criteria == 2"
                      >
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="separate_rate_in_report"
                            :key="printSettings.separateRate"
                            v-model="printSettings.separateRate"
                            @change="
                              (printSettings.exportTotalOnly = true),
                                shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="separate_rate_in_report"
                            >Separate Different Rate <br />
                            <span class="text-muted small"
                              >(consolidated report)</span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span v-if="shouldViewReport && billings.length">
                  <b v-if="!reportsLoading">
                    {{ billings.length || 0 }}/{{ total }}</b
                  >
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="reportsLoading"
                  ></i>
                  Item{{ reportsLoading || total != 1 ? "s" : "" }}
                  <i
                    class="fas fa-spin fa-circle-notch mx-1 pointer-cursor"
                    v-if="busy"
                    v-tooltip.top="
                      `%${
                        parseFloat(
                          ((billings.length || 0) / total) * 100
                        ).toFixed(2) || 0
                      }</br>Getting all data...`
                    "
                  >
                  </i>
                  <span v-if="filter.hcaiFormat" class="text-dark small ml-2"
                    >(HCAI Items:
                    <b>{{
                      allBillings.filter((item) => !item.totalRow).length
                    }}</b
                    >)</span
                  >
                  <button
                    class="btn btn-link mx-3"
                    @click="
                      (uiFilter.optional_columns.automaticLoading = false),
                        (isCancelled = true)
                    "
                    v-if="busy"
                  >
                    <i
                      class="fas fa-spin fa-circle-notch"
                      v-if="isCancelled"
                    ></i>
                    <span v-else>Cancel</span>
                  </button>
                </span>
              </div>

              <div class="d-flex flex-wrap">
                <div class="d-flex mt-3 mr-3">
                  <i
                    class="
                      fa fa-info-circle
                      mr-5
                      pointer-cursor
                      d-flex
                      justify-content-start
                      align-items-center
                    "
                    v-if="busy"
                    v-tooltip.top="
                      'Buttons will be enabled after all data is loaded successfully.'
                    "
                  ></i>
                  <button
                    class="btn btn-theme text-uppercase"
                    @click="handlePrintButton"
                    :disabled="!shouldViewReport || reportsLoading || busy"
                  >
                    <i class="fas fa-print fa-lg"></i> Print
                  </button>
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Print settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="combine_cells_in_report"
                            v-model="printSettings.combine_cells"
                          />
                          <label
                            class="custom-control-label"
                            for="combine_cells_in_report"
                            >Combine Cells</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_second_logo_in_report"
                            v-model="printSettings.show_second_logo_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_second_logo_in_report"
                            >Second Logo</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_name_in_report"
                            v-model="printSettings.show_name_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_name_in_report"
                            >Name</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_email_in_report"
                            v-model="printSettings.show_email_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_email_in_report"
                            >Email</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_location_in_report"
                            v-model="printSettings.show_location_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_location_in_report"
                            >Location</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_phone_number_in_report"
                            v-model="printSettings.show_phone_number_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_phone_number_in_report"
                            >Phone Number</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_claim_number_in_report"
                            v-model="printSettings.show_claim_number_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_claim_number_in_report"
                            >Claim Number</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_date_of_loss_in_report"
                            v-model="printSettings.show_date_of_loss_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_date_of_loss_in_report"
                            >Date of Loss</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_date_of_birth_in_report"
                            v-model="printSettings.show_date_of_birth_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_date_of_birth_in_report"
                            >Date of Birth</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_insurer_in_report"
                            v-model="printSettings.show_insurer_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_insurer_in_report"
                            >Insurer</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_funding_source_in_report"
                            v-model="
                              printSettings.show_funding_source_in_report
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_funding_source_in_report"
                            >Fund Source</label
                          >
                        </div>
                      </div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="show_lawyer_in_report"
                            v-model="printSettings.show_lawyer_in_report"
                          />
                          <label
                            class="custom-control-label"
                            for="show_lawyer_in_report"
                            >lawyer</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-danger text-uppercase mt-3 mr-3"
                  @click="handleSavePdfButton"
                  v-if="!hcaiForm"
                  :disabled="!shouldViewReport || reportsLoading || busy"
                >
                  <i class="fas fa-lg fa-file-pdf"></i>
                  Save PDF
                </button>
                <div class="d-flex mr-3 mt-3" :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Billing-Report"
                    worksheet="Billing Report"
                    :fields="excel_fields"
                    :before-generate="loadRestOfData"
                    default-value=""
                    header="Billing Report"
                  >
                    <button
                      class="btn btn-deep-success text-uppercase"
                      :disabled="!shouldViewReport || reportsLoading || busy"
                    >
                      <i class="far fa-file-excel fa-lg"></i> Save Excel
                    </button>
                  </JsonExcel>
                  <div
                    class="btn-group excel-control-group"
                    :class="`${
                      uiFilter.optional_columns.group_by_criteria != 0
                        ? ''
                        : 'transparent'
                    }`"
                  >
                    <button
                      type="button"
                      class="btn p-1"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i class="fas fa-cog"></i>
                    </button>
                    <div
                      class="dropdown-menu print-menu excel-menu"
                      @click="$event.stopPropagation()"
                    >
                      <h3 class="mx-3 my-2">
                        <i class="fas fa-cog mr-2"></i>Excel settings
                      </h3>
                      <div class="dropdown-divider"></div>
                      <div class="dropdown-item">
                        <div class="custom-control custom-switch">
                          <input
                            :key="printSettings.exportTotalOnly"
                            type="checkbox"
                            class="custom-control-input"
                            id="show_total_only_in_report"
                            v-model="printSettings.exportTotalOnly"
                            @change="
                              shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="show_total_only_in_report"
                            >Export Total Only</label
                          >
                        </div>
                      </div>
                      <div
                        :class="`dropdown-item ${
                          printSettings.exportTotalOnly ? '' : 'disabled'
                        }`"
                        v-if="uiFilter.optional_columns.group_by_criteria == 2"
                      >
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="separate_rate_in_report"
                            :key="printSettings.separateRate"
                            v-model="printSettings.separateRate"
                            @change="
                              (printSettings.exportTotalOnly = true),
                                shouldViewReport && getReportByFilterHelper()
                            "
                          />
                          <label
                            class="custom-control-label"
                            for="separate_rate_in_report"
                            >Separate Different Rate <br />
                            <span class="text-muted small"
                              >(consolidated report)</span
                            ></label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div :class="busy ? 'disabled' : ''">
                  <JsonExcel
                    :data="printExcelData"
                    name="CRM-Billing-Report.csv"
                    :fields="csv_fields"
                    :before-generate="loadRestOfData"
                    default-value=""
                    type="csv"
                  >
                    <button
                      class="btn btn-deep-success text-uppercase mt-3 mr-3"
                      :disabled="!shouldViewReport || reportsLoading || busy"
                    >
                      <i class="fa fa-file-csv fa-lg"></i> Save CSV
                    </button>
                  </JsonExcel>
                </div>
                <button
                  class="mt-3 mr-3 btn btn-1a3f3f text-uppercase"
                  @click="generateInvoice"
                  v-if="!hcaiForm"
                  :disabled="!shouldViewReport || reportsLoading || busy"
                >
                  <i class="fas fa-file-invoice-dollar fa-lg"></i> Generate
                  Invoice
                </button>

                <button
                  class="mt-3 btn btn-deep-success text-uppercase mr-3"
                  @click="generateQuickbooksCsv"
                  v-if="!hcaiForm"
                  :disabled="!shouldViewReport || reportsLoading || busy"
                >
                  <i class="fa fa-file-csv fa-lg"></i> Generate Quickbooks CSV
                </button>

                <VueJsonToCsv
                  v-show="false"
                  :json-data="printCSVData"
                  csv-title="C3-Quickbooks-Billing-Report"
                  :before-generate="loadRestOfData"
                  :labels="qb_csv_fields"
                  @error="
                    (val) => {
                      console.err(val);
                    }
                  "
                >
                  <button
                    class="mt-3 btn btn-deep-success text-uppercase"
                    ref="quickbook"
                    :disabled="!shouldViewReport || reportsLoading || busy"
                  >
                    <i class="fa fa-file-csv fa-lg"></i> Generate Quickbooks CSV
                  </button>
                </VueJsonToCsv>

                <button
                  v-if="hcaiForm"
                  class="mt-3 btn btn-1a3f3f mr-3"
                  :disabled="
                    !shouldViewReport ||
                    reportsLoading ||
                    busy ||
                    submittingHCAIReport
                  "
                  @click="submitHCAIReport()"
                >
                  <i
                    class="fa fa-file-invoice-dollar fa-lg"
                    v-if="!submittingHCAIReport"
                  ></i>
                  <i
                    class="fas fa-spin fa-circle-notch"
                    v-if="submittingHCAIReport"
                  ></i>
                  Submit HCAI report for invoicing
                </button>
                <span v-tooltip.top="'Coming Soon...'" v-if="hcaiForm">
                  <button class="mt-3 btn btn-1a3f3f" :disabled="true">
                    <i class="fa fa-file-invoice-dollar fa-lg"></i> Submit HCAI
                    billing to insurance for approval
                  </button>
                </span>
              </div>
            </div>
            <alert
              v-if="reportsLoading || reportIsPending"
              class="my-4"
            ></alert>
          </div>
        </div>
      </div>
    </div>
    <div
      class="reportData row firstPage printPage"
      v-if="printSettings.combine_cells && !reportsLoading && !reportIsPending"
    >
      <div class="col-md-8 reportCompanyLogo">
        <img
          :src="
            printSettings.show_second_logo_in_report && companyData.second_logo
              ? companyData.second_logo
              : companyData.logo
          "
        />
      </div>
      <div class="row col-md-12 m-0 reportHeader">
        <div class="col-md-12" v-if="companyData.show_name_in_report">
          {{ companyData.name }}
        </div>

        <div class="col-md-12" v-if="companyData.show_bio_in_report">
          {{ companyData.bio }}
        </div>
      </div>
      <div class="row PatientData">
        <div class="col-md-12">
          <p v-if="printSettings.show_email_in_report && companyData.email">
            <label>Email: </label>{{ companyData.email }}
          </p>

          <p v-if="printSettings.show_location_in_report">
            <label>Location: </label>{{ companyData.location }}
          </p>
          <p v-if="printSettings.show_lawyer_in_report">
            <label>Lawyer: </label>{{ companyData.lawyer }}
          </p>
          <p
            v-if="
              clientsNamesInReport.length == 1 &&
              printSettings.show_insurer_in_report
            "
          >
            <label>Insurer: </label>{{ insurer || "-" }}
          </p>
          <p v-if="printSettings.show_phone_number_in_report">
            <label>Phone Number: </label>{{ companyData.phone_number }}
          </p>

          <p
            v-if="
              clientsNamesInReport.length == 1 &&
              printSettings.show_name_in_report
            "
          >
            <label>CLIENT NAME: </label
            >{{
              clientsNamesInReport != null
                ? clientsNamesInReport.join(" , ")
                : ""
            }}
          </p>
          <p
            v-if="
              printSettings.show_claim_number_in_report &&
              clientsNamesInReport.length == 1
            "
          >
            <label>Claim Number: </label>{{ claim_number || "-" }}
          </p>
          <p
            v-if="
              printSettings.show_date_of_birth_in_report &&
              clientsNamesInReport.length == 1
            "
          >
            <label>Date Of Birth: </label>{{ date_of_birth || "-" }}
          </p>
          <p
            v-if="
              printSettings.show_date_of_loss_in_report &&
              clientsNamesInReport.length == 1
            "
          >
            <label>Date Of Loss: </label>{{ date_of_loss || "-" }}
          </p>
          <!-- TODO when API is ready printSettings.show_funding_source_in_report -->
          <p
            v-if="
              clientsNamesInReport.length == 1 &&
              printSettings.show_funding_source_in_report
            "
          >
            <label>Funding Source: </label>{{ funding_source || "-" }}
          </p>

          <p v-if="filter.dateRangeEnabled">
            <label>Report Dates Range: </label
            >{{
              this.prettyDate(this.filter.range.start, "MMM D, YYYY ") +
              " - " +
              this.prettyDate(this.filter.range.end, "MMM D, YYYY ")
            }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="!reportsLoading && shouldViewReport && !reportIsPending"
      class="card overflow-auto light-shadow border border-lg-0"
      :class="printSettings.combine_cells ? '' : 'noPrint'"
    >
      <div
        class="p-3 noPrint"
        v-if="allBillings.length == 0 && shouldViewReport && !reportsLoading"
      >
        No Data.
      </div>
      <div
        ref="billingContainerOuterTable"
        class="overflow-auto border border-lg-0"
      >
        <div class="row m-0" v-show="processing">
          <div
            class="col-12 pdfCompanyLogo"
            v-if="
              companyData.logo ||
              (printSettings.show_second_logo_in_report &&
                companyData.second_logo)
            "
          >
            <img
              :src="
                printSettings.show_second_logo_in_report &&
                companyData.second_logo
                  ? companyData.second_logo
                  : companyData.logo
              "
            />
          </div>
          <div
            class="col-12 text-center mb-4"
            v-if="companyData.show_name_in_report"
          >
            {{ companyData.name }}
          </div>
        </div>
        <table
          v-if="allBillings.length > 0 && shouldViewReport && !reportsLoading"
          class="table table-sm table-striped reportTable mb-0"
          :class="printSettings.combine_cells ? '' : 'noPrint'"
          ref="billingContainerTable"
        >
          <thead>
            <tr>
              <th rowspan="1" colspan="1">Client</th>
              <th
                rowspan="1"
                colspan="1"
                v-if="uiFilter.optional_columns.userId"
              >
                ID
              </th>
              <th rowspan="1" colspan="1">Admin/Provider</th>
              <th rowspan="1" colspan="1">Service - Name</th>
              <th rowspan="1" colspan="1">Treatment - Name</th>
              <th rowspan="1" colspan="1">Visit Date</th>
              <th
                rowspan="1"
                colspan="1"
                v-if="!uiFilter.optional_columns.mileage"
              >
                Qty
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                Hours
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                Flat
              </th>
              <th
                v-if="uiFilter.optional_columns.mileage"
                colspan="2"
                class="text-center"
              >
                Mileage
                <div
                  class="d-flex justify-content-between w-100 border-top pt-1"
                >
                  <div>$</div>
                  <div>Km</div>
                </div>
              </th>
              <th rowspan="1" colspan="1">Rate</th>
              <th rowspan="1" colspan="1" v-if="uiFilter.optional_columns.amt">
                Amt
              </th>
              <th rowspan="1" colspan="1">Expenses</th>
              <th rowspan="1" colspan="1" v-if="uiFilter.optional_columns.tax">
                Tax
              </th>
              <th
                v-if="uiFilter.optional_columns.interest"
                colspan="2"
                class="text-center"
              >
                Interest
                <div
                  class="d-flex justify-content-between w-100 border-top pt-1"
                >
                  <div>$</div>
                  <div>%</div>
                </div>
              </th>
              <th
                rowspan="1"
                colspan="1"
                v-if="uiFilter.optional_columns.subTotal"
              >
                Sub Total
              </th>
              <th rowspan="1" colspan="1">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class="totalRow like-header">
              <td :colspan="uiFilter.optional_columns.mileage ? 3 : 6"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.userId"></td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  !uiFilter.optional_columns.amt
                "
              ></td>
              <td class="text-right">Total:</td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalHours || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalFlat || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalMileage || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalKm || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.mileage">-</td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  uiFilter.optional_columns.amt
                "
              >
                -
              </td>
              <td class="moneyField">
                {{
                  `${+parseFloat(totalExpenses || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                {{
                  `${+parseFloat(totalTax || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField" v-if="uiFilter.optional_columns.interest">
                {{
                  `${+parseFloat(totalInterestAmount || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.interest">-</td>

              <td class="moneyField" v-if="uiFilter.optional_columns.subTotal">
                {{
                  `${+parseFloat(totalSubSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
            <tr
              v-for="client in allBillings"
              :key="client.client_id"
              :class="{ subServiceTotalRow: client.totalRow }"
            >
              <td v-if="!client.totalRow">
                {{
                  client.appointment != null
                    ? getAptClient(client.appointment).name || ""
                    : ""
                }}
              </td>
              <td v-if="!client.totalRow && uiFilter.optional_columns.userId">
                {{
                  client.appointment != null
                    ? getAptClient(client.appointment).user_id || ""
                    : ""
                }}
              </td>
              <td v-if="!client.totalRow">
                {{
                  client.appointment
                    ? getAptProvider(client.appointment).name || ""
                    : ""
                }}
              </td>
              <td>
                {{ client.service != null ? client.service.name : "-" }}
              </td>
              <td v-if="!client.totalRow">
                {{
                  client.appointment.treatment != null
                    ? client.appointment.treatment.name
                    : "-"
                }}
              </td>
              <td v-if="!client.totalRow">
                {{
                  client.appointment != null
                    ? prettyDate(
                        adjustDate(client.appointment.start_time),
                        "MMM D, YYYY"
                      ) +
                      ":" +
                      prettyDate(
                        adjustDate(client.appointment.start_time),
                        "hh:mm a"
                      ) +
                      " - " +
                      prettyDate(
                        adjustDate(client.appointment.end_time),
                        "hh:mm a"
                      )
                    : ""
                }}
              </td>
              <td v-if="!client.totalRow && !uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(client.qty || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                {{
                  client.hours
                    ? `${+parseFloat(client.hours).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                :class="`${client.flat ? 'moneyField' : ''}`"
                colspan="1"
              >
                {{
                  client.flat
                    ? `${+parseFloat(client.flat).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
                :class="`${client.mileage ? 'moneyField' : ''}`"
              >
                {{
                  client.mileage
                    ? `${+parseFloat(client.mileage).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.mileage"
                rowspan="1"
                colspan="1"
              >
                {{
                  client.km
                    ? `${+parseFloat(client.km).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td class="moneyField" v-if="!client.totalRow">
                {{
                  `${+parseFloat(client.client_rate || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                class="moneyField"
                v-if="!client.totalRow && uiFilter.optional_columns.amt"
              >
                {{
                  `${+parseFloat(client.amt || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td
                class="moneyField"
                v-if="!client.totalRow"
                rowspan="1"
                colspan="1"
              >
                {{
                  `${+parseFloat(
                    (client.appointment || {}).expenses || 0
                  ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td
                class="moneyField"
                v-if="!client.totalRow && uiFilter.optional_columns.tax"
                rowspan="1"
                colspan="1"
              >
                {{
                  `${+parseFloat(client.calculated_tax || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.interest"
                rowspan="1"
                colspan="1"
              >
                {{
                  client.interest_amount
                    ? `${+parseFloat(client.interest_amount).toFixed(
                        2
                      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "-"
                }}
              </td>
              <td
                v-if="!client.totalRow && uiFilter.optional_columns.interest"
                rowspan="1"
                colspan="1"
              >
                {{
                  client.interest_percentage
                    ? `${+parseFloat(client.interest_percentage).toFixed(
                        2
                      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "-"
                }}
              </td>

              <td
                class="moneyField"
                v-if="!client.totalRow && uiFilter.optional_columns.subTotal"
              >
                {{
                  `${+parseFloat(client.client_subTotal || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td class="moneyField" v-if="!client.totalRow">
                {{
                  `${+parseFloat(client.totalWithHst || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <!--  group by service  -->
              <td rowspan="1" colspan="1" v-if="client.totalRow">
                {{ `` }}
              </td>
              <td rowspan="1" colspan="3" v-if="client.totalRow">
                {{ client.totalService.split(";; ")[0] }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.userId"
              ></td>
              <td
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && !uiFilter.optional_columns.mileage"
              >
                {{
                  `${+parseFloat(client.totalQty || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  client.totalHours
                    ? `${+parseFloat(client.totalHours).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                :class="`${client.totalFlat ? 'moneyField' : ''}`"
                v-if="client.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  client.totalFlat
                    ? `${+parseFloat(client.totalFlat).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                :class="`${client.totalMileage ? 'moneyField' : ''}`"
                v-if="client.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  client.totalMileage
                    ? `${+parseFloat(client.totalMileage).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.mileage"
              >
                {{
                  client.totalKm
                    ? `${+parseFloat(client.totalKm).toFixed(2)}`.replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        "$1,"
                      )
                    : "-"
                }}
              </td>
              <td rowspan="1" colspan="1" v-if="client.totalRow">
                {{ `-` }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.amt"
              >
                {{
                  `${+parseFloat(client.totalAmount || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="client.totalRow"
              >
                {{
                  `${+parseFloat(client.totalExpenses || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.tax"
              >
                {{
                  `${+parseFloat(client.totalCalculatedTax || 0).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>

              <td
                rowspan="1"
                colspan="1"
                :class="`${client.totalInterestAmount ? 'moneyField' : ''}`"
                v-if="client.totalRow && uiFilter.optional_columns.interest"
              >
                {{
                  client.totalInterestAmount
                    ? `${+parseFloat(client.totalInterestAmount).toFixed(
                        2
                      )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "-"
                }}
              </td>
              <td
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.interest"
              >
                {{ `-` }}
              </td>
              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="client.totalRow && uiFilter.optional_columns.subTotal"
              >
                {{
                  `${+parseFloat(client.subTotal || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td
                class="moneyField"
                rowspan="1"
                colspan="1"
                v-if="client.totalRow"
              >
                {{
                  `${+parseFloat(client.totalWithHst || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
            <tr class="totalRow">
              <td :colspan="uiFilter.optional_columns.mileage ? 3 : 6"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
              <td colspan="1" v-if="uiFilter.optional_columns.userId"></td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  !uiFilter.optional_columns.amt
                "
              ></td>
              <td class="text-right">Total:</td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalHours || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalFlat || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalMileage || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td v-if="uiFilter.optional_columns.mileage">
                {{
                  `${+parseFloat(totalKm || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.mileage">-</td>
              <td
                colspan="1"
                v-if="
                  uiFilter.optional_columns.mileage &&
                  uiFilter.optional_columns.amt
                "
              >
                -
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalExpenses || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                {{
                  `${+parseFloat(totalTax || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td class="moneyField" v-if="uiFilter.optional_columns.interest">
                {{
                  `${+parseFloat(totalInterestAmount || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
              <td colspan="1" v-if="uiFilter.optional_columns.interest">-</td>

              <td class="moneyField" v-if="uiFilter.optional_columns.subTotal">
                {{
                  `${+parseFloat(totalSubSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>

              <td class="moneyField">
                {{
                  `${+parseFloat(totalSum || 0).toFixed(2)}`.replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <mugen-scroll
          class="sticky-bottom-100"
          :handler="loadMore"
          :should-handle="
            !busy &&
            !reportsLoading &&
            shouldViewReport &&
            paginationUsed &&
            !uiFilter.optional_columns.automaticLoading &&
            !reportIsPending
          "
          scroll-container="wrap"
        >
          <alert
            v-if="busy && !reportIsPending"
            class="
              text-center
              p-2
              mb-0
              mobile-tablet-loadmore mobile-tablet-shadow-card
              rounded-0
            "
            ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
            data...</alert
          >
        </mugen-scroll>
      </div>
    </div>
    <div class="container printableReport" v-if="allBillings.length > 0">
      <div
        class="reportData row firstPage printPage"
        v-if="!printSettings.combine_cells"
      >
        <div class="col-md-8 reportCompanyLogo">
          <img
            :src="
              printSettings.show_second_logo_in_report &&
              companyData.second_logo
                ? companyData.second_logo
                : companyData.logo
            "
          />
        </div>
        <div class="row col-md-12 m-0 reportHeader">
          <div class="col-md-12" v-if="companyData.show_name_in_report">
            {{ companyData.name }}
          </div>

          <div class="col-md-12" v-if="companyData.show_bio_in_report">
            {{ companyData.bio }}
          </div>
        </div>
        <div class="row PatientData">
          <div class="col-md-12">
            <p v-if="printSettings.show_email_in_report">
              <label>Email: </label>{{ companyData.email }}
            </p>

            <p v-if="printSettings.show_location_in_report">
              <label>Location: </label>{{ companyData.location }}
            </p>
            <p v-if="printSettings.show_lawyer_in_report">
              <label>Lawyer: </label>{{ companyData.lawyer }}
            </p>
            <p
              v-if="
                clientsNamesInReport.length == 1 &&
                printSettings.show_insurer_in_report
              "
            >
              <label>Insurer: </label>{{ insurer }}
            </p>
            <p v-if="printSettings.show_phone_number_in_report">
              <label>Phone Number: </label>{{ companyData.phone_number }}
            </p>

            <p
              v-if="
                clientsNamesInReport.length == 1 &&
                printSettings.show_name_in_report
              "
            >
              <label>CLIENT NAME: </label
              >{{
                clientsNamesInReport != null
                  ? clientsNamesInReport.join(" , ")
                  : ""
              }}
            </p>
            <p
              v-if="
                printSettings.show_claim_number_in_report &&
                clientsNamesInReport.length == 1
              "
            >
              <label>Claim Number: </label>{{ claim_number }}
            </p>
            <p
              v-if="
                printSettings.show_date_of_birth_in_report &&
                clientsNamesInReport.length == 1
              "
            >
              <label>Date Of Birth: </label>{{ date_of_birth }}
            </p>
            <p
              v-if="
                printSettings.show_date_of_loss_in_report &&
                clientsNamesInReport.length == 1
              "
            >
              <label>Date Of Loss: </label>{{ date_of_loss }}
            </p>
            <!-- TODO when API is ready printSettings.show_funding_source_in_report -->
            <p
              v-if="
                clientsNamesInReport.length == 1 &&
                printSettings.show_funding_source_in_report
              "
            >
              <label>Funding Source: </label>{{ funding_source }}
            </p>

            <p v-if="filter.dateRangeEnabled">
              <label>Report Dates Range: </label
              >{{
                this.prettyDate(this.filter.range.start, "MMM D, YYYY ") +
                " - " +
                this.prettyDate(this.filter.range.end, "MMM D, YYYY ")
              }}
            </p>
          </div>
        </div>
      </div>
      <!-- report Pages -->

      <div v-if="!printSettings.combine_cells">
        <div
          class="reportData row printPage"
          v-for="clientReports in this.groupedByClientId"
          :key="`${clientReports.id || Math.random()}_${
            (clientReports.service || {}).id
          }`"
        >
          <div class="row">
            <div
              class="pageTopReportInfoHeader col-12"
              style="
                font-weight: bold;
                border-bottom: 1px solid;
                margin-bottom: 10px;
              "
            >
              <div
                class="col-6"
                style="float: left"
                v-if="printSettings.show_name_in_report"
              >
                {{ companyData.name }} Report
              </div>

              <div
                class="col-6"
                style="float: right; text-align: right"
                v-show="filter.dateRangeEnabled"
              >
                Report Dates Range:
                {{
                  prettyDate(filter.range.start, "MMM D, YYYY ") +
                  " - " +
                  prettyDate(filter.range.end, "MMM D, YYYY ")
                }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div
                class="col-md-5"
                style="text-align: center; margin: 0px auto"
              >
                <img
                  :src="
                    printSettings.show_second_logo_in_report &&
                    companyData.second_logo
                      ? companyData.second_logo
                      : companyData.logo
                  "
                  style="height: 100px"
                  class="reportCompanyLogo"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="col-4" v-if="printSettings.show_name_in_report">
                <label style="font-weight: bold; text-align: right"
                  >Name:</label
                >

                {{
                  clientReports != null && clientReports[0].client != null
                    ? clientReports[0].client.name
                    : "-"
                }}
              </div>
              <div
                class="col-4"
                v-if="printSettings.show_claim_number_in_report"
              >
                <label style="font-weight: bold; text-align: right"
                  >Claim Number:</label
                >
                {{
                  clientReports != null && clientReports[0].client != null
                    ? getInvoiceClientById(clientReports[0].client.user_id)
                        .claim_number
                    : "-"
                }}
              </div>
              <div class="col-4" v-if="printSettings.show_insurer_in_report">
                <label style="font-weight: bold; text-align: right"
                  >Insurer:</label
                >

                {{
                  clientReports != null && clientReports[0].client != null
                    ? getInvoiceClientById(clientReports[0].client.user_id)
                        .insurer
                    : "-"
                }}
              </div>
              <div
                class="col-4"
                v-if="printSettings.show_date_of_loss_in_report"
              >
                <label style="font-weight: bold">Date Of Loss:</label>
                {{
                  clientReports != null && clientReports[0].client != null
                    ? prettyDate(
                        getInvoiceClientById(clientReports[0].client.user_id)
                          .injured_at
                      )
                    : "-"
                }}
              </div>
              <div
                class="col-4"
                v-if="printSettings.show_date_of_birth_in_report"
              >
                <label style="font-weight: bold">Date Of Birth:</label>
                {{
                  clientReports != null && clientReports[0].client != null
                    ? prettyDate(
                        getInvoiceClientById(clientReports[0].client.user_id)
                          .birth_date,
                        "MMM DD, YYYY"
                      )
                    : "-"
                }}
              </div>
              <div
                class="col-4"
                v-if="printSettings.show_funding_source_in_report"
              >
                <label style="font-weight: bold">Funding Source:</label>
                {{
                  clientReports != null && clientReports[0].client != null
                    ? getFundingSourceLabel(
                        getInvoiceClientById(clientReports[0].client.user_id)
                          .funding_source
                      )
                    : "-"
                }}
              </div>
            </div>
          </div>
          <!-- Print Table -->
          <table class="table table-sm table-striped mb-0">
            <thead v-if="clientReports != null">
              <tr>
                <th
                  :rowspan="uiFilter.optional_columns.mileage ? '2' : '1'"
                  colspan="1"
                >
                  Service
                </th>
                <th
                  :rowspan="uiFilter.optional_columns.mileage ? '2' : '1'"
                  colspan="1"
                >
                  Treatment
                </th>
                <th rowspan="2" colspan="1">Visit Date</th>
                <th
                  rowspan="1"
                  :colspan="`${uiFilter.optional_columns.amt ? '3' : '2'}`"
                  v-if="!uiFilter.optional_columns.mileage"
                  class="text-center"
                >
                  Hours
                </th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  :rowspan="uiFilter.optional_columns.mileage ? '2' : '1'"
                >
                  Rate
                </th>
                <th
                  v-if="
                    uiFilter.optional_columns.mileage &&
                    uiFilter.optional_columns.amt
                  "
                  rowspan="2"
                >
                  Amt
                </th>
                <th rowspan="2" colspan="1">Provider</th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="2"
                  colspan="1"
                >
                  Hours
                </th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="2"
                  colspan="1"
                >
                  Flat
                </th>
                <th
                  v-if="uiFilter.optional_columns.mileage"
                  rowspan="1"
                  colspan="2"
                  class="text-center"
                >
                  Mileage
                </th>
                <th rowspan="2" colspan="1">Expenses</th>
                <th
                  rowspan="2"
                  colspan="1"
                  v-if="uiFilter.optional_columns.tax"
                >
                  Tax
                </th>
                <th
                  v-if="uiFilter.optional_columns.interest"
                  rowspan="1"
                  colspan="2"
                  class="text-center"
                >
                  Interest
                </th>
                <th
                  rowspan="2"
                  colspan="1"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  Sub Total
                </th>

                <th rowspan="2" colspan="1">Total</th>
              </tr>
              <tr v-if="!uiFilter.optional_columns.mileage">
                <th>Name</th>
                <th>Name</th>
                <th>Qty</th>
                <th>Rate</th>
                <th v-if="uiFilter.optional_columns.amt">Amt</th>
                <th v-if="uiFilter.optional_columns.interest">$</th>
                <th v-if="uiFilter.optional_columns.interest">%</th>
              </tr>
              <tr v-if="uiFilter.optional_columns.mileage">
                <th>$</th>
                <th>Km</th>
                <th v-if="uiFilter.optional_columns.interest">$</th>
                <th v-if="uiFilter.optional_columns.interest">%</th>
              </tr>
            </thead>
            <tbody>
              <tr class="totalRow like-header">
                <td :colspan="uiFilter.optional_columns.mileage ? 2 : 5"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
                <td colspan="2" v-if="uiFilter.optional_columns.mileage"></td>
                <td class="text-right">Total:</td>

                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalHours = calculateTotalPerKey(
                        clientReports,
                        "hours"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalFlat = calculateTotalPerKey(
                        clientReports,
                        "flat"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalMileage = calculateTotalPerKey(
                        clientReports,
                        "mileage"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalKm = calculateTotalPerKey(
                        clientReports,
                        "km"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (clientTotalExpenses = calculateTotalPerKey(
                        clientReports,
                        "expenses"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                  {{
                    `${+parseFloat(
                      (clientTotalTax = calculateTotalTax(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.interest"
                >
                  {{
                    `${+parseFloat(
                      (clientTotalInterestAmount = calculateTotalPerKey(
                        clientReports,
                        "interest_amount"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.interest">-</td>

                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(
                      (clientTotalSubSum = calculateTotalSubSum(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (clientTotalSum = calculateTotalSum(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
              <tr
                v-for="reportItem in clientReports"
                :key="`${reportItem.id || Math.random()}_${
                  reportItem.service.id
                }`"
              >
                <td>
                  {{ reportItem.service.name }}
                </td>
                <td>
                  {{
                    reportItem.appointment.treatment
                      ? reportItem.appointment.treatment.name
                      : ""
                  }}
                </td>
                <td>
                  {{
                    reportItem.appointment != null
                      ? prettyDate(
                          adjustDate(reportItem.appointment.start_time),
                          "MMM D, YYYY"
                        ) +
                        ":" +
                        prettyDate(
                          adjustDate(reportItem.appointment.start_time),
                          "hh:mm a"
                        ) +
                        " - " +
                        prettyDate(
                          adjustDate(reportItem.appointment.end_time),
                          "hh:mm a"
                        )
                      : ""
                  }}
                </td>
                <td v-if="!uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(reportItem.qty || 0).toFixed(2)}`.replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(reportItem.client_rate || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.amt">
                  {{
                    `${+parseFloat(reportItem.amt || 0).toFixed(2)}`.replace(
                      /(\d)(?=(\d{3})+(?!\d))/g,
                      "$1,"
                    )
                  }}
                </td>

                <td>
                  {{
                    reportItem.appointment
                      ? getAptProvider(reportItem.appointment).name || ""
                      : ""
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    reportItem.hours
                      ? `${+parseFloat(reportItem.hours).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td
                  v-if="uiFilter.optional_columns.mileage"
                  :class="`${reportItem.flat ? 'moneyField' : ''}`"
                >
                  {{
                    reportItem.flat
                      ? `${+parseFloat(reportItem.flat).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td
                  v-if="uiFilter.optional_columns.mileage"
                  :class="`${reportItem.mileage ? 'moneyField' : ''}`"
                >
                  {{
                    reportItem.mileage
                      ? `${+parseFloat(reportItem.mileage).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    reportItem.km
                      ? `${+parseFloat(reportItem.km).toFixed(2)}`.replace(
                          /(\d)(?=(\d{3})+(?!\d))/g,
                          "$1,"
                        )
                      : "-"
                  }}
                </td>
                <td class="moneyField" rowspan="1" colspan="1">
                  {{
                    `${+parseFloat(
                      (reportItem.appointment || {}).expenses || 0
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  rowspan="1"
                  colspan="1"
                  v-if="uiFilter.optional_columns.tax"
                >
                  {{
                    `${+parseFloat(reportItem.calculated_tax || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  rowspan="1"
                  colspan="1"
                  v-if="uiFilter.optional_columns.interest"
                >
                  {{
                    `${+parseFloat(reportItem.interest_amount || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.interest">
                  {{
                    `${+parseFloat(reportItem.interest_percentage || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(reportItem.client_subTotal || 0).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${(+parseFloat(
                      parseFloat(reportItem.client_subTotal || 0) +
                        (reportItem.calculated_tax &&
                          parseFloat(reportItem.calculated_tax || 0)) +
                        (uiFilter.optional_columns.interest &&
                          parseFloat(reportItem.interest_amount || 0))
                    )).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
              <!-- total Row -->
              <tr class="totalRow">
                <td :colspan="uiFilter.optional_columns.mileage ? 2 : 5"></td>
                <td colspan="1" v-if="uiFilter.optional_columns.amt"></td>
                <td colspan="2" v-if="uiFilter.optional_columns.mileage"></td>
                <td class="text-right">Total:</td>

                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalHours = calculateTotalPerKey(
                        clientReports,
                        "hours"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalFlat = calculateTotalPerKey(
                        clientReports,
                        "flat"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalMileage = calculateTotalPerKey(
                        clientReports,
                        "mileage"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.mileage">
                  {{
                    `${+parseFloat(
                      (clientTotalKm = calculateTotalPerKey(
                        clientReports,
                        "km"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>

                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (clientTotalExpenses = calculateTotalPerKey(
                        clientReports,
                        "expenses"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField" v-if="uiFilter.optional_columns.tax">
                  {{
                    `${+parseFloat(
                      (clientTotalTax = calculateTotalTax(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.interest"
                >
                  {{
                    `${+parseFloat(
                      (clientTotalInterestAmount = calculateTotalPerKey(
                        clientReports,
                        "interest_amount"
                      ))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td v-if="uiFilter.optional_columns.interest">-</td>

                <td
                  class="moneyField"
                  v-if="uiFilter.optional_columns.subTotal"
                >
                  {{
                    `${+parseFloat(
                      (clientTotalSubSum = calculateTotalSubSum(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
                <td class="moneyField">
                  {{
                    `${+parseFloat(
                      (clientTotalSum = calculateTotalSum(clientReports))
                    ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!--End Page -->
      </div>
    </div>
    <loader v-if="processing"
      ><b class="mt-4 text-center" v-html="processingText"></b>
      <button
        class="btn btn-link"
        @click="isCancelled = true"
        v-if="billings.length != total"
      >
        <i class="fas fa-spin fa-circle-notch" v-if="isCancelled"></i>
        <span v-else>Cancel</span>
      </button>
    </loader>
  </div>
</template>
<script>
import dateFormat from "@/utils/dateFormat";
import { mapActions, mapState } from "vuex";
import { orderBy } from "lodash";
import helpers from "@/utils/helpers";
import Multiselect from "vue-multiselect";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import JsonExcel from "vue-json-excel";
import VueJsonToCsv from "vue-json-to-csv";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import MugenScroll from "vue-mugen-scroll";

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: "billing-report",
  props: ["userIdProp", "isAdminProp", "hcaiForm", "ohipForm"],
  components: {
    Multiselect,
    JsonExcel,
    VueJsonToCsv,
    MugenScroll,
  },
  data() {
    return {
      showReportClicked: false,
      isCancelled: false,
      excelOnly: false,
      reportIsPending: false,
      submittingHCAIReport: false,
      printExcelData: [],
      qbCsvData: [],
      printSettings: {
        combine_cells: false,
        separateRate: false,
        exportTotalOnly: false,
      },
      printCSVData: [],
      invoiceErrors: [],
      percent: -1,
      processing: false,
      processingText: "",
      hst_percentage: 0.13,
      shouldViewReport: false,
      gettingClasses: false,
      gettingClinics: false,
      gettingProfessions: false,
      gettingServices: false,
      services: [],
      mainServices: [],
      paginationUsed: true,
      busy: false,
      limit: 300,
      totalTax: 0,
      totalInterestAmount: 0,
      totalSum: 0,
      totalExpenses: 0,
      totalHours: 0,
      totalHoursMoney: 0,
      totalFlat: 0,
      totalMileage: 0,
      totalKm: 0,
      clientTotalTax: 0,
      clientTotalInterestAmount: 0,
      clientTotalSum: 0,
      clientTotalExpenses: 0,
      clientTotalHours: 0,
      clientTotalFlat: 0,
      clientTotalMileage: 0,
      clientTotalKm: 0,
      clientsNamesInReport: [],
      date_of_loss: "",
      date_of_birth: "",
      insurer: "",
      funding_source: "",
      claim_number: "",
      groupedByClientId: [],
      printClicked: "",
      allBillings: [],
      hideHcai: false,
      saving: false,
      fundingSourceOptions: [
        { value: "government", label: "Government" },
        { value: "insurance_company", label: "Insurance company" },
        { value: "other", label: "Other" },
      ],
      masks: {
        input: "YYYY-MM-DD",
      },
      select: {
        client: null,
        admin: null,
      },
      uiFilter: {
        groupByOptions: [
          { value: 0, label: "None" },
          { value: 1, label: "Services" },
          { value: 2, label: "Clients" },
        ],
        nonMainGroupByOptions: [
          { value: 0, label: "None" },
          { value: 1, label: "Services" },
        ],
        billableOptions: [
          { value: -1, label: "All" },
          { value: 0, label: "Non Billable" },
          { value: 1, label: "Billable" },
        ],
        optional_columns: {
          combine_cells: true,
          group_by_criteria: 1,
          mileage: false,
          userId: true,
          amt: true,
          unit: true,
          tax: true,
          interest: !this.hcaiForm,
          subTotal: true,
          automaticLoading: false,
        },
      },
      submittedFilter: {
        clients: "",
        clientsAdmin: "",
        classification: "",
        clinic: "",
        professions: "",
        service: "",
        dateRangeEnabled: true,
        filterIsDirty: true,
        range: {
          start: new Date(),
          end: new Date(),
        },
        pagination: false,
        hcaiFormat: this.hcaiForm,
      },
      filter: {
        clients: "",
        clientsAdmin: "",
        classification: "",
        clinic: "",
        professions: "",
        service: "",
        dateRangeEnabled: true,
        filterIsDirty: true,
        range: {
          start: new Date(),
          end: new Date(),
        },
        non_billable: 1,
        pagination: false,
        hcaiFormat: this.hcaiForm,
      },
    };
  },
  watch: {
    showReportClicked: function (newVal) {
      if (newVal && !this.isCancelled) {
        if (this.uiFilter.optional_columns.automaticLoading) {
          return this.loadRestOfData(true).then(() => {
            this.showReportClicked = false;
          });
        } else {
          this.showReportClicked = false;
        }
      }
    },
    isCancelled: function (newVal) {
      if (newVal && this.reportIsPending) {
        this.getReportByFilterHelper().then(() => {
          this.reportIsPending = false;
          this.isCancelled = false;
        });
      }
    },
  },
  computed: {
    ...mapState({
      billings: (state) => state.reports.billingData,
      reportsLoading: (state) => state.reports.isLoading,
      companyData: (state) => state.settings.companyProfile,
      users: (state) => state.security.usersWithRoles,
      authUser: (state) => state.auth.user,
      usersWithRolesLoading: (state) => state.security.usersWithRolesLoading,
      invoiceClients: (state) => state.patients.invoicePatients,
      current: (state) => state.reports.pagination.current,
      total: (state) => state.reports.pagination.total,
      professions: (state) => state.professions.data,
      classifications: (state) =>
        state.classifications.data.filter((item) => item.role_id == 3),
      clinics: (state) => state.clinics.data,
    }),
    userFolders: (state) => state.folders,
    clientsList: function () {
      if (this.users && this.users.length) {
        if (this.hcaiForm) {
          const hcaiClients = this.invoiceClients
            .filter((client) => client.hcai_active)
            .map((item) => item.user_id);
          return this.users.filter((user) =>
            hcaiClients.includes(user.user_id)
          );
        }
        return this.users.filter((user) => user.roleId === 3);
      } else {
        return [];
      }
    },
    clientsAdmins: function () {
      if (this.users && this.users.length) {
        return this.users.filter(
          (user) => user.roleId === 1 || user.roleId === 5
        );
      } else {
        return [];
      }
    },
    excel_fields: function () {
      let fields = {};
      if (
        this.uiFilter.optional_columns.group_by_criteria != 1 ||
        !this.printSettings.exportTotalOnly
      ) {
        if (this.filter.hcaiFormat) {
          fields = {
            ...fields,
            "Billing ID": {
              field: "id",
              callback: (value) => {
                return value;
              },
            },
            "Client First": {
              field: "appointment",
              callback: (value) => {
                if (value == "total") {
                  return "TOTAL";
                } else if (value == "-----------") {
                  return value;
                } else if (typeof value === "string") {
                  if (
                    value.split(";; ").length == 2 &&
                    value.indexOf("Total for") == -1
                  ) {
                    return value.split(";; ")[0].split(", ")[1];
                  }
                  return value.split(";; ")[0];
                }
                return `${this.getAptClient(value).name || ""}`.split(", ")[1];
              },
            },
            "Client Last": {
              field: "appointment",
              callback: (value) => {
                if (value == "total" || value == "-----------") {
                  return "-----------";
                } else if (typeof value === "string") {
                  if (
                    value.split(";; ").length == 2 &&
                    value.indexOf("Total for") == -1
                  ) {
                    return value.split(";; ")[0].split(", ")[0];
                  }
                  return "-----------";
                }
                return `${this.getAptClient(value).name || ""}`.split(", ")[0];
              },
            },
            "Claim Number": {
              field: "appointment",
              callback: (value) => {
                if (
                  value == "total" ||
                  value == "-----------" ||
                  typeof value === "string"
                ) {
                  return "-----------";
                }
                return `${(
                  this.ClientClaimNumber(this.getAptClient(value).user_id)
                    .toString()
                    .match(/.{1,28}/g) || []
                ).join("")}&nbsp;`;
              },
            },
            "Policy Number": {
              field: "appointment",
              callback: (value) => {
                if (
                  value == "total" ||
                  value == "-----------" ||
                  typeof value === "string"
                ) {
                  return "-----------";
                }
                return `${(
                  this.ClientPolicyNumber(this.getAptClient(value).user_id)
                    .toString()
                    .match(/.{1,28}/g) || []
                ).join("")}&nbsp;`;
              },
            },
            DOB: {
              field: "appointment",
              callback: (value) => {
                if (value == "total" || value == "-----------") {
                  return "-----------";
                } else if (typeof value === "string") {
                  if (
                    value.split(";; ").length == 2 &&
                    value.indexOf("Total for") == -1
                  ) {
                    return value.split(";; ")[1];
                  }
                  return "-----------";
                }
                return `${
                  this.ClientBirthDate(this.getAptClient(value).user_id) || ""
                }`;
              },
            },
          };
        } else {
          fields = {
            ...fields,
            "Client Name": {
              field: "appointment",
              callback: (value) => {
                if (value == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>TOTAL</td></tr></table>";
                } else if (value == "total" || value == "-----------") {
                  return value;
                } else if (typeof value === "string") {
                  if (value.split(";; ").length == 2) {
                    return value.split(";; ")[0];
                  }
                  return value;
                }
                return `${this.getAptClient(value).name || ""}`;
              },
            },
          };
        }
      }
      if (
        this.uiFilter.optional_columns.userId &&
        (this.uiFilter.optional_columns.group_by_criteria != 1 ||
          !this.printSettings.exportTotalOnly)
      ) {
        fields = {
          ...fields,
          ID: {
            field: "clientId",
            callback: (value) => {
              if (value == "TOTAL__") {
                return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
              }
              if (value == "total" || value == "-----------") {
                return "------------";
              }
              return value;
            },
          },
        };
      }
      if (
        (!this.printSettings.separateRate &&
          !this.printSettings.exportTotalOnly) ||
        this.uiFilter.optional_columns.group_by_criteria != 2
      ) {
        if (
          (!this.printSettings.separateRate &&
            !this.printSettings.exportTotalOnly) ||
          this.uiFilter.optional_columns.group_by_criteria == 0 ||
          (this.uiFilter.optional_columns.group_by_criteria == 1 &&
            !this.printSettings.exportTotalOnly)
        ) {
          if (this.filter.hcaiFormat) {
            fields = {
              ...fields,
              "Provider First": {
                field: "appointment",
                callback: (value) => {
                  if (typeof value === "string") {
                    if (value == "TOTAL__") {
                      return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                    }
                    return "------------";
                  }
                  return `${this.getAptProvider(value).name || ""}`.split(
                    ", "
                  )[1];
                },
              },
              "Provider Last": {
                field: "appointment",
                callback: (value) => {
                  if (typeof value === "string") {
                    if (value == "TOTAL__") {
                      return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                    }
                    return "------------";
                  }
                  return `${this.getAptProvider(value).name || ""}`.split(
                    ", "
                  )[0];
                },
              },
              "Registration Number": {
                field: "college_number",
                callback: (value) => {
                  if (value == "TOTAL__") {
                    return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                  }
                  if (value == "total") {
                    return "-----------";
                  }
                  return `${(value.toString().match(/.{1,28}/g) || []).join(
                    ""
                  )}&nbsp;`;
                },
              },
              "HCAI Registration ID": {
                field: "appointment",
                callback: (value) => {
                  if (typeof value === "string") {
                    if (value == "TOTAL__") {
                      return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                    }
                    return "------------";
                  }
                  return `${(
                    (this.getAptProvider(value).hcai_registration_id || "")
                      .toString()
                      .match(/.{1,28}/g) || []
                  ).join("")}&nbsp;`;
                },
              },
              "Provider Profession": {
                field: "service",
                callback: (value) => {
                  if (value.id == "TOTAL__") {
                    return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                  }
                  if (value.id == "total" || typeof value === "string") {
                    return "-----------";
                  }
                  return (
                    this.getHCAIProfessionName(value.id) ||
                    this.getProfessionName(value.id)
                  );
                },
              },
            };
            if (
              this.uiFilter.optional_columns.userId &&
              (this.uiFilter.optional_columns.group_by_criteria != 1 ||
                !this.printSettings.exportTotalOnly)
            ) {
              fields = {
                ...fields,
                "Provider ID": {
                  field: "appointment",
                  callback: (value) => {
                    if (value == "TOTAL__") {
                      return `<table><tr style='background-color: #FFE699; font-weight:bold'><td>${
                        !this.filter.hcaiFormat ? "TOTAL" : "-----------"
                      }</td></tr></table>`;
                    }
                    if (value == "total") {
                      return this.filter.hcaiFormat ? "-----------" : "TOTAL";
                    } else if (typeof value === "string") {
                      if (value.indexOf("Total for") > -1) {
                        return "-----------";
                      }
                      return value;
                    }
                    return `${this.getAptProvider(value).user_id}`;
                  },
                },
              };
            }
          } else {
            fields = {
              ...fields,
              "Provider/Admin Name": {
                field: "appointment",
                callback: (value) => {
                  if (value == "TOTAL__") {
                    return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                  }
                  if (value == "total" || value == "-----------") {
                    return "------------";
                  } else if (typeof value === "string") {
                    return "------------";
                  }
                  return `${this.getAptProvider(value).name || ""}`;
                },
              },
            };
          }
        }
        if (this.filter.hcaiFormat) {
          fields = {
            ...fields,
            "Service Code": {
              field: "service",
              callback: (value) => {
                if (typeof value === "string") {
                  return "------------";
                } else if (value.code == "TOTAL__") {
                  return `<table><tr style='background-color: #FFE699; font-weight:bold'><td>${
                    this.printSettings.exportTotalOnly ? "TOTAL" : "-----------"
                  }</td></tr></table>`;
                } else if (value.code == "total") {
                  return this.printSettings.exportTotalOnly
                    ? "TOTAL"
                    : "-----------";
                } else if (value.code == "-----------") {
                  return "------------";
                }
                return `${value.code || ""}`;
              },
            },
          };
        }
        fields = {
          ...fields,
          "Service Name": {
            field: "service",
            callback: (value) => {
              if (value.name == "TOTAL__") {
                return `<table><tr style='background-color: #FFE699; font-weight:bold'><td>${
                  this.printSettings.exportTotalOnly && !this.filter.hcaiFormat
                    ? "TOTAL"
                    : "-----------"
                }</td></tr></table>`;
              }
              if (value.name == "total") {
                return this.printSettings.exportTotalOnly &&
                  !this.filter.hcaiFormat
                  ? "TOTAL"
                  : "-----------";
              } else if (typeof value === "string") {
                return "------------";
              }
              return `${value.name || ""}`;
            },
          },
        };
        if (this.filter.hcaiFormat && !this.printSettings.exportTotalOnly) {
          fields = {
            ...fields,
            MVA: {
              field: "appointment",
              callback: (value) => {
                if (value == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                if (
                  value == "total" ||
                  value == "-----------" ||
                  typeof value === "string"
                ) {
                  return "-----------";
                }
                return "y";
              },
            },
            CAT: {
              field: "appointment",
              callback: (value) => {
                if (value == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                if (
                  value == "total" ||
                  value == "-----------" ||
                  typeof value === "string"
                ) {
                  return "-----------";
                }
                return `${
                  this.ClientHcaiCat(this.getAptClient(value).user_id) || ""
                }`;
              },
            },
          };
        }
        if (
          ((!this.printSettings.separateRate ||
            this.uiFilter.optional_columns.group_by_criteria == 1) &&
            !this.printSettings.exportTotalOnly) ||
          this.uiFilter.optional_columns.group_by_criteria == 0
        ) {
          fields = {
            ...fields,
            "Visit Date": {
              field: "appointment",
              callback: (appointment) => {
                if (appointment == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                if (appointment == "total" || appointment == "-----------") {
                  return "---------------------";
                } else if (typeof appointment === "string") {
                  return "---------------------";
                }
                if (this.filter.hcaiFormat) {
                  return appointment != null
                    ? this.prettyDate(
                        this.adjustDate(appointment.start_time),
                        "MM/DD/YYYY"
                      )
                    : "";
                }
                return appointment != null
                  ? this.prettyDate(
                      this.adjustDate(appointment.start_time),
                      "MM/DD/YYYY"
                    ) +
                      "  (" +
                      this.prettyDate(
                        this.adjustDate(appointment.start_time),
                        "hh:mm a"
                      ) +
                      " - " +
                      this.prettyDate(
                        this.adjustDate(appointment.end_time),
                        "hh:mm a"
                      ) +
                      ")"
                  : "";
              },
            },
            MEMO: {
              field: "appointment",
              callback: (appointment) => {
                if (appointment == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                if (appointment == "total" || appointment == "-----------") {
                  return "-----------";
                } else if (typeof appointment === "string") {
                  return "-----------";
                }
                return appointment && appointment.memo ? appointment.memo : "-";
              },
            },
          };
        }
      }

      if (
        !this.printSettings.exportTotalOnly ||
        (this.printSettings.separateRate &&
          this.uiFilter.optional_columns.group_by_criteria == 2) ||
        this.uiFilter.optional_columns.group_by_criteria == 0
      ) {
        fields = {
          ...fields,
          Rate: {
            field: "client_rate",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }
      if (this.uiFilter.optional_columns.mileage) {
        fields = {
          ...fields,
          "Hours(H)": {
            field: "hours",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Hours($)": {
            field: "hoursMoney",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          Flat: {
            field: "flat",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Mileage($)": {
            field: "mileage",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
          "Mileage(KM)": {
            field: "km",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      } else {
        fields = {
          ...fields,
          QTY: {
            field: "qty",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }
      if (
        (!this.printSettings.separateRate &&
          !this.printSettings.exportTotalOnly) ||
        this.uiFilter.optional_columns.group_by_criteria == 0 ||
        this.uiFilter.optional_columns.group_by_criteria == 1
      ) {
        if (this.uiFilter.optional_columns.unit) {
          fields = {
            ...fields,
            Measure: {
              field: "service",
              callback: (value) => {
                if (value.unit == "TOTAL__") {
                  return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                }
                if (value.unit == "total") {
                  return "-----------";
                }
                return `${value.unit || ""}`;
              },
            },
          };
        }
      }

      if (this.uiFilter.optional_columns.amt) {
        fields = {
          ...fields,
          AMT: {
            field: "amt",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }

      if (this.uiFilter.optional_columns.tax) {
        fields = {
          ...fields,
          Tax: {
            field: "calculated_tax",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
      }
      if (this.uiFilter.optional_columns.interest) {
        fields = {
          ...fields,
          "Interest($)": {
            field: "interest_amount",
            callback: (value) => {
              if (isNaN(parseFloat(value))) {
                if (value.indexOf("TOTAL__") > -1) {
                  return (
                    "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                    `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                      2
                    )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                    "</td></tr></table>"
                  );
                }
                return value;
              }
              return `${+parseFloat(value).toFixed(2)}`.replace(
                /(\d)(?=(\d{3})+(?!\d))/g,
                "$1,"
              );
            },
          },
        };
        if (
          !this.printSettings.exportTotalOnly ||
          this.uiFilter.optional_columns.group_by_criteria !== 1
        ) {
          fields = {
            ...fields,
            "Interest(%)": {
              field: "interest_percentage",
              callback: (value) => {
                if (isNaN(parseFloat(value))) {
                  if (value == "TOTAL__") {
                    return "<table><tr style='background-color: #FFE699; font-weight:bold'><td>-----------</td></tr></table>";
                  }
                  return value;
                }
                return `${+parseFloat(value).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                );
              },
            },
          };
        }
      }

      fields = {
        ...fields,
        Expenses: {
          field: "expenses",
          callback: (value) => {
            if (value == "total" || value == "-----------") {
              return "-----------";
            } else if (
              typeof value === "string" &&
              value.indexOf("Total") > -1
            ) {
              return "-----------";
            }
            if (`${value}`.indexOf("TOTAL__") > -1) {
              return (
                "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                `${+parseFloat(value.split("TOTAL__")[1]).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                ) +
                "</td></tr></table>"
              );
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          },
        },
      };

      if (this.uiFilter.optional_columns.subTotal) {
        fields = {
          ...fields,
          "Sub Total": {
            field: "client_subTotal",
            callback: (value) => {
              if (value == "-----------") {
                return value;
              }
              if (`${value}`.indexOf("TOTAL__") > -1) {
                return (
                  "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                  `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "</td></tr></table>"
                );
              }

              return `${+parseFloat(`${value}`.replace(/,/g, "")).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            },
          },
        };
      }
      fields = {
        ...fields,
        Total: {
          field: "totalWithHst",
          callback: (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return (
                  "<table><tr style='background-color: #FFE699; font-weight:bold'><td>" +
                  `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                    2
                  )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") +
                  "</td></tr></table>"
                );
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          },
        },
      };

      return fields;
    },
    csv_fields: function () {
      const fields = JSON.parse(JSON.stringify(this.excel_fields || {}));
      if (this.excel_fields) {
        if (fields["Client Name"]) {
          fields["Client Name"].callback = (value) => {
            if (value == "total" || value == "TOTAL__") {
              return "TOTAL";
            } else if (value == "-----------") {
              return value;
            } else if (typeof value === "string") {
              if (value.split(";; ").length == 2) {
                return value.split(";; ")[0];
              }
              return value;
            }
            return `${this.getAptClient(value).name || ""}`;
          };
        }
        if (fields["ID"]) {
          fields["ID"].callback = (value) => {
            if (
              value == "TOTAL__" ||
              value == "total" ||
              value == "-----------"
            ) {
              return "------------";
            }
            return value;
          };
        }
        if (fields["Provider First"]) {
          fields["Provider First"].callback = (value) => {
            if (typeof value === "string") {
              return "------------";
            }
            return `${this.getAptProvider(value).name || ""}`.split(", ")[1];
          };
        }
        if (fields["Provider Last"]) {
          fields["Provider Last"].callback = (value) => {
            if (typeof value === "string") {
              return "------------";
            }
            return `${this.getAptProvider(value).name || ""}`.split(", ")[0];
          };
        }
        if (fields["Registration Number"]) {
          fields["Registration Number"].callback = (value) => {
            if (value == "total" || value == "TOTAL__") {
              return "-----------";
            }
            return value;
          };
        }
        if (fields["HCAI Registration ID"]) {
          fields["HCAI Registration ID"].callback = (value) => {
            if (typeof value === "string") {
              return "------------";
            }
            return `${this.getAptProvider(value).hcai_registration_id || ""}`;
          };
        }
        if (fields["Provider Profession"]) {
          fields["Provider Profession"].callback = (value) => {
            if (
              value.id == "TOTAL__" ||
              value.id == "total" ||
              typeof value === "string"
            ) {
              return "-----------";
            }
            return (
              this.getHCAIProfessionName(value.id) ||
              this.getProfessionName(value.id)
            );
          };
        }
        if (fields["Provider ID"]) {
          fields["Provider ID"].callback = (value) => {
            if (value == "TOTAL__" || value == "total") {
              return this.filter.hcaiFormat ? "-----------" : "TOTAL";
            } else if (typeof value === "string") {
              if (value.indexOf("Total for") > -1) {
                return "-----------";
              }
              return value;
            }
            return `${this.getAptProvider(value).user_id}`;
          };
        }
        if (fields["Provider/Admin Name"]) {
          fields["Provider/Admin Name"].callback = (value) => {
            if (
              value == "TOTAL__" ||
              value == "total" ||
              value == "-----------"
            ) {
              return "------------";
            } else if (typeof value === "string") {
              return "------------";
            }
            return `${this.getAptProvider(value).name || ""}`;
          };
        }
        if (fields["Service Code"]) {
          fields["Service Code"].callback = (value) => {
            if (typeof value === "string") {
              return "------------";
            } else if (value.code == "TOTAL__" || value.code == "total") {
              return this.printSettings.exportTotalOnly
                ? "TOTAL"
                : "-----------";
            } else if (value.code == "-----------") {
              return "------------";
            }
            return `${value.code || ""}`;
          };
        }
        if (fields["Service Name"]) {
          fields["Service Name"].callback = (value) => {
            if (value.name == "total" || value.name == "TOTAL__") {
              return this.printSettings.exportTotalOnly &&
                !this.filter.hcaiFormat
                ? "TOTAL"
                : "-----------";
            } else if (typeof value === "string") {
              return "------------";
            }
            return `${value.name || ""}`;
          };
        }
        if (fields["MVA"]) {
          fields["MVA"].callback = (value) => {
            if (
              value == "TOTAL__" ||
              value == "total" ||
              value == "-----------" ||
              typeof value === "string"
            ) {
              return "-----------";
            }
            return "y";
          };
        }
        if (fields["CAT"]) {
          fields["CAT"].callback = (value) => {
            if (
              value == "TOTAL__" ||
              value == "total" ||
              value == "-----------" ||
              typeof value === "string"
            ) {
              return "-----------";
            }
            return `${
              this.ClientHcaiCat(this.getAptClient(value).user_id) || ""
            }`;
          };
        }
        if (fields["Visit Date"]) {
          fields["Visit Date"].callback = (appointment) => {
            if (
              appointment == "TOTAL__" ||
              appointment == "total" ||
              appointment == "-----------"
            ) {
              return "---------------------";
            } else if (typeof appointment === "string") {
              return "---------------------";
            }
            if (this.filter.hcaiFormat) {
              return appointment != null
                ? this.prettyDate(
                    this.adjustDate(appointment.start_time),
                    "MM/DD/YYYY"
                  )
                : "";
            }
            return appointment != null
              ? this.prettyDate(
                  this.adjustDate(appointment.start_time),
                  "MM/DD/YYYY"
                ) +
                  "  (" +
                  this.prettyDate(
                    this.adjustDate(appointment.start_time),
                    "hh:mm a"
                  ) +
                  " - " +
                  this.prettyDate(
                    this.adjustDate(appointment.end_time),
                    "hh:mm a"
                  ) +
                  ")"
              : "";
          };
        }
        if (fields["MEMO"]) {
          fields["MEMO"].callback = (appointment) => {
            if (
              appointment == "TOTAL__" ||
              appointment == "total" ||
              appointment == "-----------"
            ) {
              return "-----------";
            } else if (typeof appointment === "string") {
              return "-----------";
            }
            return appointment && appointment.memo ? appointment.memo : "-";
          };
        }
        if (fields["Rate"]) {
          fields["Rate"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value == "TOTAL__") {
                return "-----------";
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Hours"]) {
          fields["Hours"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Flat"]) {
          fields["Flat"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Mileage($)"]) {
          fields["Mileage($)"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Mileage(KM)"]) {
          fields["Mileage(KM)"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Interest($)"]) {
          fields["Interest($)"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Interest(%)"]) {
          fields["Interest(%)"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value == "TOTAL__" || value == "total") {
                return "-----------";
              }
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["QTY"]) {
          fields["QTY"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Measure"]) {
          fields["Measure"].callback = (value) => {
            if (value.unit == "TOTAL__" || value.unit == "total") {
              return "-----------";
            }
            return `${value.unit || ""}`;
          };
        }
        if (fields["AMT"]) {
          fields["AMT"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Tax"]) {
          fields["Tax"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Expenses"]) {
          fields["Expenses"].callback = (value) => {
            if (value == "total" || value == "-----------") {
              return "-----------";
            } else if (
              typeof value === "string" &&
              value.indexOf("Total") > -1
            ) {
              return "-----------";
            }
            if (`${value}`.indexOf("TOTAL__") > -1) {
              return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Sub Total"]) {
          fields["Sub Total"].callback = (value) => {
            if (value == "-----------") {
              return value;
            }
            if (`${value}`.indexOf("TOTAL__") > -1) {
              return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                2
              )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            }

            return `${+parseFloat(`${value}`.replace(/,/g, "")).toFixed(
              2
            )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
          };
        }
        if (fields["Total"]) {
          fields["Total"].callback = (value) => {
            if (isNaN(parseFloat(value))) {
              if (value.indexOf("TOTAL__") > -1) {
                return `${+parseFloat(value.split("TOTAL__")[1]).toFixed(
                  2
                )}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              }
              return value;
            }
            return `${+parseFloat(value).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            );
          };
        }
        if (fields["Claim Number"]) {
          fields["Claim Number"].callback = (value) => {
            if (
              value == "total" ||
              value == "TOTAL__" ||
              value == "-----------" ||
              typeof value === "string"
            ) {
              return "-----------";
            }
            return this.ClientPolicyNumber(this.getAptClient(value).user_id);
          };
        }
        if (fields["Policy Number"]) {
          fields["Policy Number"].callback = (value) => {
            if (
              value == "total" ||
              value == "TOTAL__" ||
              value == "-----------" ||
              typeof value === "string"
            ) {
              return "-----------";
            }
            return this.ClientPolicyNumber(this.getAptClient(value).user_id);
          };
        }

        if (fields["Billing ID"]) {
          fields["Billing ID"].callback =
            this.excel_fields["Billing ID"].callback;
        }
        if (fields["Client First"]) {
          fields["Client First"].callback =
            this.excel_fields["Client First"].callback;
        }
        if (fields["Client Last"]) {
          fields["Client Last"].callback =
            this.excel_fields["Client Last"].callback;
        }
        if (fields["DOB"]) {
          fields["DOB"].callback = this.excel_fields["DOB"].callback;
        }
      }
      return fields;
    },
    qb_csv_fields: function () {
      let csv_fields = {
        invoiceNo: { title: "*InvoiceNo" },
        customer: { title: "*Customer" },
        invoiceDate: { title: "*Invoice Date" },
        dueDate: { title: "*Due Date" },
        terms: { title: "Terms" },
        serviceDate: { title: "Service Date" },
        location: { title: "Location" },
        memo: { title: "Memo" },
        item: { title: "Item(Product/Service)" },
        itemDescription: { title: "ItemDescription" },
      };

      if (this.uiFilter.optional_columns.mileage) {
        csv_fields = {
          ...csv_fields,
          flat: { title: "ItemFlat" },
          hours: { title: "ItemHours" },
          mileage: { title: "ItemMileage" },
        };
      } else {
        csv_fields = {
          ...csv_fields,
          qty: { title: "ItemQuantity" },
        };
      }
      csv_fields = {
        ...csv_fields,
        rate: { title: "ItemRate" },
        amount: { title: "*ItemAmount" },
        taxCode: { title: "*ItemTaxCode" },
        taxAmount: { title: "ItemTaxAmount" },
      };
      return csv_fields;
    },
  },
  mounted() {
    if (!this.userIdProp) {
      this.getInvoiceClients();
      this.gettingServices = true;
      this.getBillingServices().then((data) => {
        this.services = data || [];
        this.mainServices = this.services.filter(
          (item) => !item.parent_service_id
        );
        this.gettingServices = false;
      });
    }
  },
  created() {
    this.getCompanyProfile().then((companyData) => {
      if (companyData) {
        this.printSettings = {
          ...this.printSettings,
          show_name_in_report: companyData.show_name_in_report,
          show_email_in_report: companyData.show_email_in_report,
          show_claim_number_in_report: companyData.show_claim_number_in_report,
          show_date_of_loss_in_report: companyData.show_date_of_loss_in_report,
          show_date_of_birth_in_report:
            companyData.show_date_of_birth_in_report,
          show_insurer_in_report: companyData.show_insurer_in_report,
          show_lawyer_in_report: companyData.show_lawyer_in_report,
          show_location_in_report: companyData.show_location_in_report,
          show_phone_number_in_report: companyData.show_phone_number_in_report,
          show_funding_source_in_report:
            companyData.show_funding_source_in_report,
        };
      }
    });
    if (this.userIdProp) {
      this.filter.clients = this.userIdProp;
      this.select.client = this.clientsList.filter(
        (c) => c.user_id == this.userIdProp
      )[0];
    } else {
      this.gettingClasses = true;
      this.getClassifications().then(() => {
        this.gettingClasses = false;
      });
      this.gettingProfessions = true;
      this.getAllProfessions().then(() => {
        this.gettingProfessions = false;
      });
      this.gettingClinics = true;
      this.getClinics().then(() => {
        this.gettingClinics = false;
      });
    }
  },
  methods: {
    ...mapActions({
      getReportsByDates: "reports/getBillingSpecificDates",
      getCompanyProfile: "settings/getCompanyProfile",
      getClassifications: "classifications/get",
      createInvoice: "invoices/create",
      getServiceInvoice: "invoices/serviceInvoices",
      getBillingServices: "reports/getServices",
      getInvoiceClients: "patients/getInvoicePatients",
      setBillingCurrentPage: "reports/setBillingCurrentPage",
      sendHCAIReport: "reports/submitHCAIReport",
      getAllProfessions: "professions/all",
      getClinics: "clinics/get",
    }),
    dateFormat: dateFormat,
    dateIsBetween: helpers.dateIsBetween,
    markeFilterAsDirty: function () {
      this.filter.filterIsDirty = true;
    },
    updateSelectedClient: function () {
      this.filter.clients = (this.select.client || {}).user_id || "";
      this.filter.clientsAdmin = "";
      this.select.admin = null;
      this.markeFilterAsDirty();
    },
    updateSelectedAdmin: function () {
      this.filter.clientsAdmin = (this.select.admin || {}).user_id || "";
      this.filter.clients = "";
      this.select.client = null;
      this.markeFilterAsDirty();
    },
    getProfessionName: function (id) {
      if (this.services && this.services.length) {
        return (
          (this.services.filter((serv) => serv.id == id)[0] || {}).profession ||
          ""
        );
      }
      return "";
    },
    getHCAIProfessionName: function (id) {
      if (this.services && this.services.length) {
        return (
          (this.services.filter((serv) => serv.id == id)[0] || {})
            .hcai_profession || ""
        );
      }
      return "";
    },
    ClientBirthDate: function (userId) {
      if (this.invoiceClients && this.invoiceClients.length) {
        const client =
          this.invoiceClients.filter((item) => item.user_id == userId)[0] || {};
        if (client.birth_date) {
          return this.prettyDate(client.birth_date, "MM/DD/YYYY");
        }
      }
      return "";
    },
    ClientClaimNumber: function (userId) {
      if (this.invoiceClients && this.invoiceClients.length) {
        const client =
          this.invoiceClients.filter((item) => item.user_id == userId)[0] || {};
        return client.claim_number || "";
      }
      return "";
    },
    ClientPolicyNumber: function (userId) {
      if (this.invoiceClients && this.invoiceClients.length) {
        const client =
          this.invoiceClients.filter((item) => item.user_id == userId)[0] || {};
        return client.policy_number || "";
      }
      return "";
    },
    ClientHcaiCat: function (userId) {
      if (this.invoiceClients && this.invoiceClients.length) {
        const client =
          this.invoiceClients.filter((item) => item.user_id == userId)[0] || {};
        if (client.hcai_cat) {
          return "y";
        }
      }
      return "n";
    },
    adjustDate: function (time) {
      return dayjs(time)
        .add(-1 * dayjs(time).utcOffset(), "minute")
        .utc()
        .format();
    },
    removeOldWrongData: function (input) {
      return (input || []).filter(
        (value, index, self) =>
          index ===
            self.findIndex(
              (t) =>
                (t.appointment || {}).id === (value.appointment || {}).id &&
                (t.service || {}).id === (value.service || {}).id &&
                (t.appointment || {}).start_time ===
                  (value.appointment || {}).start_time &&
                (t.appointment || {}).end_time ===
                  (value.appointment || {}).end_time &&
                t.total == value.total
            ) || value.totalRow
      );
    },
    checkHCAIStatus: function (input) {
      if (!this.filter.hcaiFormat) {
        return input;
      }
      let vm = this;
      return input.filter((item) => {
        if (
          item &&
          item.appointment &&
          vm.getAptClient(item.appointment).user_id
        ) {
          const client =
            vm.invoiceClients.filter(
              (client) =>
                client.user_id == vm.getAptClient(item.appointment).user_id
            )[0] || {};
          return client.hcai_active;
        }
        return false;
      });
    },
    filterByDate: function (currentPage = 0) {
      let vm = this;
      //filter based on date
      if (
        this.submittedFilter.range.start != null &&
        this.submittedFilter.range.end != null
      ) {
        const payload = {};
        const startDateTime = `${dateFormat(
          vm.filter.range.start,
          "YYYY-MM-DD"
        )}T00:00:00.000000Z`;
        const endDateTime = `${dateFormat(
          vm.filter.range.end,
          "YYYY-MM-DD"
        )}T23:59:59.000000Z`;

        const canadaOffsetStart = dayjs
          .tz(new Date(startDateTime), "America/Toronto")
          .utcOffset();
        const canadaOffsetEnd = dayjs
          .tz(new Date(endDateTime), "America/Toronto")
          .utcOffset();
        payload.start = dayjs(startDateTime)
          .add(canadaOffsetStart, "minute")
          .utc()
          .format();

        payload.end = dayjs(endDateTime)
          .add(canadaOffsetEnd, "minute")
          .utc()
          .format();

        if (this.submittedFilter.classification != "") {
          payload.classes = `[${this.submittedFilter.classification
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.clinic != "") {
          payload.clinics = `[${this.submittedFilter.clinic
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.service != "") {
          payload.services = `[${this.submittedFilter.service
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.clients != "") {
          payload.clientId = this.submittedFilter.clients;
        }

        if (this.submittedFilter.professions != "") {
          payload.professions = `[${this.submittedFilter.professions
            .map((item) => item.id)
            .join(",")}]`;
        }

        if (this.submittedFilter.clientsAdmin != "") {
          payload.admin = this.submittedFilter.clientsAdmin;
        }

        payload.billable = this.submittedFilter.non_billable;

        payload.hcai = this.hcaiForm ? 1 : this.hideHcai ? 2 : 0;

        if (this.submittedFilter.pagination) {
          payload.limit = this.limit;
          if (currentPage > 0) {
            payload.page = currentPage;
          }
        }

        return this.getReportsByDates(payload).then(() => {
          this.allBillings = this.itemsWithApt(this.billings);
          if (!this.busy) {
            this.filter.filterIsDirty = false;
          }
          return Promise.resolve();
        });
      }
      return Promise.resolve();
    },
    itemsWithApt: function (input) {
      let vm = this;
      return JSON.parse(
        JSON.stringify(
          (input || []).filter(
            (item) =>
              item.appointment &&
              [1, 2, 5].includes(vm.getAptProvider(item.appointment).role_id)
          )
        )
      );
    },
    calculateTotalSubSum(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        return tot + parseFloat(arr.client_subTotal || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalSum(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr.totalWithHst || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalTax(objectToCalculate) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr.calculated_tax || 0);
        // set initial value as 0
      }, 0);
    },
    calculateTotalPerKey(objectToCalculate, key) {
      return objectToCalculate.reduce(function (tot, arr) {
        // return the sum with previous value
        return tot + parseFloat(arr[key] || 0);
        // set initial value as 0
      }, 0);
    },
    getFundingSourceLabel: function (value) {
      var foundValue = this.fundingSourceOptions.find(
        (item) => item.value == value
      );
      if (foundValue != null) {
        return foundValue.label;
      } else {
        return "";
      }
    },
    groupClientsForReport() {
      let vm = this;
      this.totalSubSum = 0;

      let tempBilling = orderBy(
        this.allBillings,
        ["appointment.start_time"],
        ["asc"]
      );

      tempBilling.map(function (item) {
        if (
          item.appointment == null ||
          !vm.getAptClient(item.appointment).user_id
        ) {
          return;
        }
        item.provider = vm.getAptProvider(item.appointment);
        item.client = vm.getAptClient(item.appointment);
        item.expenses = vm.getAptExpenses(item.appointment);
        item.serviceNameLowered = (
          (item.service || {}).name || ""
        ).toLowerCase();
        item.clientNameLowered = ((item.client || {}).name || "").toLowerCase();
        switch (item.service.unit) {
          case "FT":
          case "FLAT":
            item.flat = item.rate;
            break;
          case "KM":
            item.mileage = item.qty * item.rate;
            item.km = item.qty;
            break;
          default:
            item.hours = item.qty;
            item.hoursMoney = item.qty * item.rate;
            break;
        }
        item.clientId = item.client.user_id;
        item.client_rate = item.rate;
        item.client_subTotal = item.qty * item.rate;
        item.client_total = item.qty * item.rate;
        item.amt = item.qty * item.client_rate;

        if (item.service.unit == "FT" || item.service.unit == "FLAT") {
          item.amt = item.rate;
          item.client_total = item.rate;
          item.client_subTotal = item.rate;
        }

        if (!vm.hcaiForm) {
          item.client_total += item.interest_amount;
        }

        // HST calculations
        item.hst_calculation = 0;
        if (item.service.taxable == true && item.service.unit != "KM") {
          item.hst_calculation =
            parseFloat(item.client_subTotal) * parseFloat(item.tax);
        } else {
          item.calculated_tax = 0;
          item.tax = 0;
        }

        item.totalWithHst =
          parseFloat(item.client_subTotal) + parseFloat(item.hst_calculation);
        if (item.service.parent_service_id == null) {
          // add expenses for main service only.
          item.totalWithHst += parseFloat(
            (item.appointment || {}).expenses ? item.appointment.expenses : 0
          );
        }

        if (!vm.hcaiForm) {
          item.totalWithHst += parseFloat(item.interest_amount);
        }

        //calculate tax and total row
        item.calculated_tax =
          item.service.taxable == true ? item.tax * item.client_subTotal : 0;
        if (
          item.appointment.expenses &&
          item.service.parent_service_id == null
        ) {
          item.client_subTotal =
            item.appointment.expenses + item.client_subTotal;
        }
      });

      tempBilling = this.removeOldWrongData(tempBilling);
      tempBilling = this.checkHCAIStatus(tempBilling);

      this.totalSubSum = this.calculateTotalSubSum(tempBilling);
      this.totalSum = this.calculateTotalSum(tempBilling);

      this.totalTax = this.calculateTotalTax(tempBilling);
      this.totalHours = this.calculateTotalPerKey(tempBilling, "hours");
      this.totalHoursMoney = this.calculateTotalPerKey(
        tempBilling,
        "hoursMoney"
      );
      this.totalFlat = this.calculateTotalPerKey(tempBilling, "flat");
      this.totalMileage = this.calculateTotalPerKey(tempBilling, "mileage");
      this.totalKm = this.calculateTotalPerKey(tempBilling, "km");
      this.totalExpenses = this.calculateTotalPerKey(tempBilling, "expenses");
      this.totalInterestAmount = this.calculateTotalPerKey(
        tempBilling,
        "interest_amount"
      );
      this.groupedByClientId = this.groupBy(tempBilling, "clientId");

      if (this.uiFilter.optional_columns.group_by_criteria == 0) {
        this.allBillings = tempBilling;
      } else if (this.uiFilter.optional_columns.group_by_criteria == 1) {
        // Group by service
        const tempBilling2 = [];
        tempBilling = orderBy(
          tempBilling,
          ["serviceNameLowered", "appointment.start_time", "clientNameLowered"],
          ["asc", "asc", "asc"]
        );
        let totalQty = 0;
        let totalAmount = 0;
        let total = 0;
        let subTotal = 0;
        let totalTax = 0;
        let totalWithHst = 0;
        let totalExpenses = 0;
        let totalInterestAmount = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        tempBilling.forEach((item, index) => {
          tempBilling2.push(item);
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmount += parseFloat(item.amt || 0);
          total += parseFloat(item.client_total || 0);
          subTotal += parseFloat(item.client_subTotal || 0);
          totalWithHst += parseFloat(item.totalWithHst || 0);
          totalExpenses += parseFloat((item.appointment || {}).expenses || 0);
          totalInterestAmount += parseFloat(item.interest_amount || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          if (
            index == tempBilling.length - 1 ||
            item.clientId != tempBilling[index + 1].clientId ||
            item.service.id != tempBilling[index + 1].service.id
          ) {
            const newItem = {
              id: "",
              totalRow: true,
              totalService: `Total for ${
                item.service.parent_service_id ? "Sub Service" : "Service"
              } (${item.service.name})`,
              totalServiceCode: item.service.code,
              totalServiceUnit: item.service.unit,
              totalQty: totalQty,
              totalHours: totalHours,
              totalHoursMoney: totalHoursMoney,
              totalMileage: totalMileage,
              totalKm: totalKm,
              totalFlat: totalFlat,
              totalRate: "-----------",
              totalInterestPercentage: item.interest_percentage,
              totalAmount: totalAmount,
              totalExpenses: totalExpenses,
              totalInterestAmount: totalInterestAmount,
              totalCalculatedTax: totalTax,
              total: total,
              subTotal: subTotal,
              client_subTotal: subTotal,
              totalWithHst: totalWithHst,
              appointment: item.appointment,
              clientId: item.clientId,
            };
            tempBilling2.push(newItem);
            totalQty =
              totalHours =
              totalHoursMoney =
              totalMileage =
              totalKm =
              totalFlat =
              totalAmount =
              totalTax =
              total =
              subTotal =
              totalWithHst =
              totalExpenses =
              totalInterestAmount =
                0;
          }
        });
        const vm = this;
        this.allBillings = tempBilling2.filter(
          (item) => item.appointment && vm.getAptClient(item.appointment)
        );
      } else if (this.uiFilter.optional_columns.group_by_criteria == 2) {
        // Group by client
        const tempBilling2 = [];
        tempBilling = orderBy(
          tempBilling,
          ["clientNameLowered", "appointment.start_time"],
          ["asc", "asc"]
        );
        let totalQty = 0;
        let totalAmount = 0;
        let total = 0;
        let subTotal = 0;
        let totalTax = 0;
        let totalWithHst = 0;
        let totalExpenses = 0;
        let totalInterestAmount = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        tempBilling.forEach((item, index) => {
          tempBilling2.push(item);
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmount += parseFloat(item.amt || 0);
          total += parseFloat(item.client_total || 0);
          subTotal += parseFloat(item.client_subTotal || 0);
          totalWithHst += parseFloat(item.totalWithHst || 0);
          totalExpenses += parseFloat((item.appointment || {}).expenses || 0);
          totalInterestAmount += parseFloat(item.interest_amount || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          if (
            index == tempBilling.length - 1 ||
            item.clientId != tempBilling[index + 1].clientId
          ) {
            const newItem = {
              id: "",
              totalRow: true,
              totalService: this.printSettings.exportTotalOnly
                ? `Total for ${(item.client || {}).name};; ${
                    this.ClientBirthDate(
                      this.getAptClient(item.appointment).user_id
                    ) || ""
                  }`
                : `Total for ${(item.client || {}).name}`,
              totalQty: totalQty,
              totalHours: totalHours,
              totalHoursMoney: totalHoursMoney,
              totalMileage: totalMileage,
              totalKm: totalKm,
              totalFlat: totalFlat,
              totalRate: "-----------",
              totalAmount: totalAmount,
              totalExpenses: totalExpenses,
              totalInterestAmount: totalInterestAmount,
              totalInterestPercentage: item.interest_percentage,
              totalCalculatedTax: totalTax,
              total: total,
              subTotal: subTotal,
              client_subTotal: subTotal,
              totalWithHst: totalWithHst,
              appointment: item.appointment,
              clientId: item.clientId,
              hst: item.hst,
            };
            tempBilling2.push(newItem);
            totalQty =
              totalHours =
              totalHoursMoney =
              totalMileage =
              totalKm =
              totalFlat =
              totalAmount =
              totalTax =
              total =
              subTotal =
              totalWithHst =
              totalExpenses =
              totalInterestAmount =
                0;
          }
        });
        const vm = this;
        this.allBillings = tempBilling2.filter(
          (item) => item.appointment && vm.getAptClient(item.appointment)
        );
      }
    },
    getReportByFilterHelper: function (excelOnly = false) {
      this.submittedFilter = JSON.parse(JSON.stringify(this.filter));
      this.excelOnly = excelOnly;
      return this.getReportByFilter();
    },
    generateExcelOnly: function () {
      this.reportIsPending = this.reportIsPending || this.busy;
      this.isCancelled = this.isCancelled || this.busy;
      return this.getReportByFilterHelper(true).then(() => {
        return this.loadRestOfData();
      });
    },
    getReportByFilter: function (currentPage = 0) {
      this.clientsNamesInReport = [];

      if (
        this.submittedFilter.dateRangeEnabled &&
        (this.filter.filterIsDirty || currentPage > 0)
      ) {
        return this.filterByDate(currentPage).then(() => {
          this.handleReportsToDisplay();
          if (!this.isCancelled) {
            this.showReportClicked = true;
          }
          return Promise.resolve();
        });
      } else {
        const showBillable = this.submittedFilter.non_billable;
        let tempBilling = JSON.parse(
          JSON.stringify(
            this.billings.filter(
              (item) =>
                item.appointment &&
                (this.submittedFilter.non_billable == -1 ||
                  item.appointment.billable == showBillable)
            )
          )
        );
        this.allBillings = this.removeOldWrongData(tempBilling);
        this.allBillings = this.checkHCAIStatus(this.allBillings);
        this.handleReportsToDisplay();
        return Promise.resolve();
      }
    },
    handleClassClientFilters: function () {
      let vm = this;
      if (
        this.submittedFilter.clients != "" ||
        this.submittedFilter.classification != "" ||
        this.submittedFilter.clinic != "" ||
        this.submittedFilter.clientsAdmin != ""
      ) {
        if (this.submittedFilter.clients != "") {
          this.clientsNamesInReport = [];
          this.clientsList.forEach(function (item) {
            if (item.user_id == vm.filter.clients) {
              vm.date_of_loss = vm.prettyDate(
                vm.getInvoiceClientById(item.user_id).injured_at
              );
              vm.date_of_birth = vm.prettyDate(
                vm.getInvoiceClientById(item.user_id).birth_date,
                "MMM DD, YYYY"
              );
              vm.insurer = vm.getInvoiceClientById(item.user_id).insurer;
              vm.funding_source = vm.getFundingSourceLabel(
                vm.getInvoiceClientById(item.user_id).funding_source
              );
              vm.claim_number = vm.getInvoiceClientById(
                item.user_id
              ).claim_number;
              vm.clientsNamesInReport.push(item.name);
            }
          });
          this.allBillings = this.allBillings.filter(
            (item) =>
              item.appointment != null &&
              vm.getAptClient(item.appointment) &&
              vm.getAptClient(item.appointment).user_id ==
                this.submittedFilter.clients
          );
        } else if (this.submittedFilter.clientsAdmin != "") {
          this.clientsNamesInReport = [];
          this.clientsList.forEach(function (item) {
            if (
              (
                (vm.getInvoiceClientById(item.user_id) &&
                  vm.getInvoiceClientById(item.user_id).managers) ||
                []
              ).includes(vm.submittedFilter.clientsAdmin)
            ) {
              vm.date_of_loss = vm.prettyDate(
                vm.getInvoiceClientById(item.user_id).injured_at
              );
              vm.date_of_birth = vm.prettyDate(
                vm.getInvoiceClientById(item.user_id).birth_date,
                "MMM DD, YYYY"
              );
              vm.insurer = vm.getInvoiceClientById(item.user_id).insurer;
              vm.funding_source = vm.getFundingSourceLabel(
                vm.getInvoiceClientById(item.user_id).funding_source
              );
              vm.claim_number = vm.getInvoiceClientById(
                item.user_id
              ).claim_number;
              vm.clientsNamesInReport.push(item.name);
            }
          });
          this.allBillings = this.allBillings.filter(
            (item) =>
              item.appointment != null &&
              vm.getAptClient(item.appointment) &&
              (
                vm.getInvoiceClientById(
                  vm.getAptClient(item.appointment).user_id
                ).managers || []
              ).includes(vm.submittedFilter.clientsAdmin)
          );
        }

        if (this.submittedFilter.classification != "") {
          let selectedClassificationsIds =
            this.submittedFilter.classification.map((item) => item.id);
          let allBillingWithClass = [];
          let vm2 = this;
          this.allBillings.forEach(function (item) {
            let vm3 = vm2;
            selectedClassificationsIds.forEach(function (selectedClassID) {
              const user = vm3.getAptClient(item.appointment || {});
              if (
                item.appointment &&
                (user.classifications_ids || []).includes(selectedClassID) &&
                !allBillingWithClass.includes(item)
              ) {
                allBillingWithClass.push(item);
              }
            });
          });
          this.allBillings = allBillingWithClass;
        }

        if (this.submittedFilter.clinic != "") {
          let selectedClinicsIds = this.submittedFilter.clinic.map(
            (item) => item.id
          );
          let allBillingWithClinics = [];
          let vm2 = this;
          this.allBillings.forEach(function (item) {
            let vm3 = vm2;
            selectedClinicsIds.forEach(function (selectedClinicID) {
              const user = vm3.getAptClient(item.appointment || {});
              if (
                item.appointment &&
                (user.clinics_ids || []).includes(selectedClinicID) &&
                !allBillingWithClinics.includes(item)
              ) {
                allBillingWithClinics.push(item);
              }
            });
          });
          this.allBillings = allBillingWithClinics;
        }
      } else {
        this.clientsNamesInReport = [];
        this.clientsList.forEach(function (item) {
          vm.clientsNamesInReport.push(item.name);
        });
      }
    },
    handleReportsToDisplay: function () {
      this.handleClassClientFilters();
      this.groupClientsForReport();
      if (!this.excelOnly) {
        this.shouldViewReport = true;
      }
      this.qbCsvData = JSON.parse(JSON.stringify(this.allBillings));
      //Format the structured data to Quickbook CSV fields
      this.formatToQuickbookCSV();
      this.handleExcelData();
    },
    handleExcelData: function () {
      if (!this.printSettings.exportTotalOnly) {
        this.printSettings.separateRate = false;
      }
      if (!this.shouldViewReport && !this.excelOnly) {
        return;
      }

      if (this.uiFilter.optional_columns.group_by_criteria == 2) {
        const printExcelDataTemp = [];
        const excelBilling = JSON.parse(JSON.stringify(this.allBillings));
        let clientBillingRate = {};
        excelBilling.forEach((item) => {
          if (item.totalRow == false || item.totalRow == undefined) {
            if (!this.printSettings.exportTotalOnly) {
              printExcelDataTemp.push(item);
            } else if (this.printSettings.separateRate) {
              let billingItem = clientBillingRate[item.client_rate];
              if (!billingItem) {
                item.appointment = `${
                  this.getAptClient(item.appointment).name || ""
                };; ${
                  this.ClientBirthDate(
                    this.getAptClient(item.appointment).user_id
                  ) || ""
                }`;
                item.service = "";
                item.hours = item.hours || 0;
                item.hoursMoney = item.hoursMoney || 0;
                item.mileage = item.mileage || 0;
                item.km = item.km || 0;
                item.flat = item.flat || 0;
                clientBillingRate[item.client_rate] = item;
              } else {
                billingItem.qty += parseFloat(item.qty || 0);
                billingItem.hours += parseFloat(item.hours || 0);
                billingItem.hoursMoney += parseFloat(item.hoursMoney || 0);
                billingItem.mileage += parseFloat(item.mileage || 0);
                // billingItem.interest_amount +=parseFloat(item.interest_amount || 0);
                billingItem.km += parseFloat(item.km || 0);
                billingItem.flat += parseFloat(item.flat || 0);
                billingItem.amt += parseFloat(item.amt || 0);
                billingItem.client_subTotal += parseFloat(
                  item.client_subTotal || 0
                );
                billingItem.calculated_tax += parseFloat(
                  item.calculated_tax || 0
                );
                billingItem.totalWithHst += parseFloat(item.totalWithHst || 0);
                billingItem.expenses += parseFloat(item.expenses || 0);
              }
            }
          } else {
            Object.keys(clientBillingRate).forEach((key) => {
              printExcelDataTemp.push(clientBillingRate[key]);
            });
            clientBillingRate = {};
            if (
              !this.filter.hcaiFormat ||
              (this.filter.hcaiFormat &&
                this.printSettings.exportTotalOnly &&
                !this.printSettings.separateRate)
            ) {
              printExcelDataTemp.push({
                qty: item.totalQty,
                hours: item.totalHours,
                hoursMoney: item.totalHoursMoney,
                mileage: item.totalMileage,
                km: item.totalKm,
                flat: item.totalFlat,
                amt: item.totalAmount,
                client_subTotal: item.client_subTotal,
                calculated_tax: item.totalCalculatedTax,
                totalWithHst: item.totalWithHst,
                client_rate: item.totalRate,
                appointment:
                  this.printSettings.exportTotalOnly &&
                  !this.printSettings.separateRate
                    ? item.totalService.replace("Total for ", "")
                    : item.totalService,
                clientId:
                  this.printSettings.exportTotalOnly &&
                  !this.printSettings.separateRate
                    ? item.clientId
                    : "-----------",
                expenses: item.totalExpenses,
                interest_amount: item.totalInterestAmount,
                interest_percentage:
                  this.printSettings.exportTotalOnly &&
                  !this.printSettings.separateRate
                    ? item.totalInterestPercentage
                    : "-----------",
                college_number: "total",
                service: {
                  name:
                    this.uiFilter.optional_columns.group_by_criteria == 0
                      ? "-----------"
                      : "total",
                  code: "total",
                  unit:
                    this.uiFilter.optional_columns.group_by_criteria == 0
                      ? "-----------"
                      : "total",
                },
              });
            }
            if (
              !this.printSettings.exportTotalOnly &&
              !this.filter.hcaiFormat
            ) {
              printExcelDataTemp.push({
                qty: "-----------",
                flat: "-----------",
                hours: "-----------",
                hoursMoney: "-----------",
                mileage: "-----------",
                km: "-----------",
                amt: "-----------",
                client_subTotal: "-----------",
                calculated_tax: "-----------",
                totalWithHst: "-----------",
                client_rate: "-----------",
                interest_percentage: "-----------",
                interest_amount: "-----------",
                appointment: "-----------",
                clientId: "-----------",
                college_number: "-----------",
                service: {
                  name: "-----------",
                  code: "-----------",
                  unit: "-----------",
                },
                expenses: "-----------",
              });
            }
          }
        });
        this.printExcelData = printExcelDataTemp;
        const originalRecords = this.allBillings.filter(
          (item) => item.totalRow == false || item.totalRow == undefined
        );
        let totalQty = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        let totalAmt = 0;
        let totalTax = 0;
        let totalSubTotal = 0;
        let totalTotal = 0;
        let totalExpenses = 0;
        let totalInterestAmount = 0;

        originalRecords.forEach((item) => {
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmt += parseFloat(item.amt || 0);
          totalTotal += parseFloat(item.totalWithHst || 0);
          totalSubTotal += parseFloat(item.client_subTotal || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          totalExpenses += parseFloat(item.expenses || 0);
          totalInterestAmount += parseFloat(item.interest_amount || 0);
        });

        if (!this.filter.hcaiFormat) {
          this.printExcelData.push({
            qty: "-----------",
            hours: "-----------",
            hoursMoney: "-----------",
            flat: "-----------",
            mileage: "-----------",
            interest_amount: "-----------",
            interest_percentage: "-----------",
            km: "-----------",
            amt: "-----------",
            client_subTotal: "-----------",
            calculated_tax: "-----------",
            totalWithHst: "-----------",
            client_rate: "-----------",
            appointment: "-----------",
            clientId: "-----------",
            college_number: "-----------",
            service: {
              name: "-----------",
              code: "-----------",
              unit: "-----------",
            },
            expenses: "-----------",
          });
          this.printExcelData.push({
            qty: `TOTAL__${totalQty}`,
            hours: `TOTAL__${totalHours}`,
            hoursMoney: `TOTAL__${totalHoursMoney}`,
            mileage: `TOTAL__${totalMileage}`,
            km: `TOTAL__${totalKm}`,
            flat: `TOTAL__${totalFlat}`,
            amt: `TOTAL__${totalAmt}`,
            client_subTotal: `TOTAL__${totalSubTotal}`,
            calculated_tax: `TOTAL__${totalTax}`,
            totalWithHst: `TOTAL__${totalTotal}`,
            client_rate: "TOTAL__",
            appointment: "TOTAL__",
            clientId: "TOTAL__",
            expenses: `TOTAL__${totalExpenses}`,
            interest_percentage: "TOTAL__",
            interest_amount: `TOTAL__${totalInterestAmount}`,
            college_number: "TOTAL__",
            service: {
              name: "TOTAL__",
              code: "TOTAL__",
              unit: "TOTAL__",
            },
          });
        }
      } else {
        this.printExcelData = this.allBillings.filter(
          (item) => item.totalRow == false || item.totalRow == undefined
        );
        let totalQty = 0;
        let totalHours = 0;
        let totalHoursMoney = 0;
        let totalMileage = 0;
        let totalKm = 0;
        let totalFlat = 0;
        let totalAmt = 0;
        let totalTax = 0;
        let totalSubTotal = 0;
        let totalTotal = 0;
        let totalExpenses = 0;
        let totalInterestAmount = 0;

        this.printExcelData.forEach((item) => {
          totalQty += parseFloat(item.qty || 0);
          totalHours += parseFloat(item.hours || 0);
          totalHoursMoney += parseFloat(item.hoursMoney || 0);
          totalMileage += parseFloat(item.mileage || 0);
          totalKm += parseFloat(item.km || 0);
          totalFlat += parseFloat(item.flat || 0);
          totalAmt += parseFloat(item.amt || 0);
          totalTotal += parseFloat(item.totalWithHst || 0);
          totalSubTotal += parseFloat(item.client_subTotal || 0);
          totalTax += parseFloat(item.calculated_tax || 0);
          totalExpenses += parseFloat(item.expenses || 0);
          totalInterestAmount += parseFloat(item.interest_amount || 0);
        });

        if (
          this.printSettings.exportTotalOnly &&
          this.uiFilter.optional_columns.group_by_criteria == 1
        ) {
          const printExcelDataTemp = [];
          const tempData = this.allBillings.filter((item) => item.totalRow);
          tempData.forEach((item) => {
            printExcelDataTemp.push({
              qty: item.totalQty,
              hours: item.totalHours,
              hoursMoney: item.totalHoursMoney,
              mileage: item.totalMileage,
              km: item.totalKm,
              flat: item.totalFlat,
              amt: item.totalAmount,
              client_subTotal: item.client_subTotal,
              calculated_tax: item.totalCalculatedTax,
              totalWithHst: item.totalWithHst,
              rate: item.client_rate,
              appointment: "-----------",
              clientId: "-----------",
              expenses: item.totalExpenses,
              interest_amount: item.totalInterestAmount,
              interest_percentage: "-----------",
              college_number: "total",
              service: {
                name: item.totalService.replace("Total for ", ""),
                code: item.totalServiceCode,
                unit: item.totalServiceUnit,
              },
            });
            this.printExcelData = printExcelDataTemp;
          });
        }

        if (!this.filter.hcaiFormat) {
          this.printExcelData.push({
            qty: "-----------",
            hours: "-----------",
            hoursMoney: "-----------",
            flat: "-----------",
            mileage: "-----------",
            km: "-----------",
            amt: "-----------",
            interest_amount: "-----------",
            interest_percentage: "-----------",
            client_subTotal: "-----------",
            calculated_tax: "-----------",
            totalWithHst: "-----------",
            client_rate: "-----------",
            appointment: "-----------",
            clientId: "-----------",
            college_number: "-----------",
            service: {
              name: "-----------",
              code: "-----------",
              unit: "-----------",
            },
            expenses: "-----------",
          });
          this.printExcelData.push({
            qty: `TOTAL__${totalQty}`,
            hours: `TOTAL__${totalHours}`,
            hoursMoney: `TOTAL__${totalHoursMoney}`,
            mileage: `TOTAL__${totalMileage}`,
            km: `TOTAL__${totalKm}`,
            flat: `TOTAL__${totalFlat}`,
            amt: `TOTAL__${totalAmt}`,
            client_subTotal: `TOTAL__${totalSubTotal}`,
            calculated_tax: `TOTAL__${totalTax}`,
            totalWithHst: `TOTAL__${totalTotal}`,
            client_rate: "TOTAL__",
            appointment: "TOTAL__",
            clientId: "TOTAL__",
            expenses: `TOTAL__${totalExpenses}`,
            interest_amount: `TOTAL__${totalInterestAmount}`,
            interest_percentage: `TOTAL__`,
            college_number: "TOTAL__",
            service: {
              name: "TOTAL__",
              code: "TOTAL__",
              unit: "TOTAL__",
            },
          });
        }
      }
    },
    groupBy: function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    prettyDate: function (date, format = null) {
      if (date == undefined || date == null) {
        return;
      }
      if (!format) {
        return dateFormat(date, "human");
      } else {
        return dateFormat(date, format);
      }
    },
    handlePrintButton: function () {
      if (this.busy) {
        return;
      }
      this.loadRestOfData().then(() => {
        this.printClicked = false;
        window.print();
      });
    },
    handleSavePdfButton: function () {
      if (this.busy) {
        return;
      }
      if (
        !this.allBillings.length ||
        !this.shouldViewReport ||
        this.reportsLoading
      ) {
        return Swal.fire({
          title: "",
          html: "Nothing to save",
          icon: "warning",
        });
      }
      return this.loadRestOfData().then(() => {
        try {
          this.processing = true;
          this.processingText = "<h3>Preparing PDF...</h3>";
          this.$forceUpdate();
          this.$refs.billingContainerTable.style.width = "min-content";
          this.$refs.billingContainerOuterTable.style.width = "min-content";
        } catch (e) {
          console.log(e);
          this.fireError();
          this.$forceUpdate();
          return;
        }
        this.$refs.billingContainerTable.style.width = "min-content";
        this.$refs.billingContainerOuterTable.style.width = "min-content";
        setTimeout(() => {
          html2pdf(this.$refs.billingContainerOuterTable, {
            margin: 0,
            filename: "CRM-Billing-Report.pdf",
            image: { type: "jpeg", quality: 1 },
            html2canvas: {
              scale: 4,
              allowTaint: true,
              useCORS: true,
              letterRendering: true,
              width: this.$refs.billingContainerTable.clientWidth,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          })
            .then(() => {
              this.$refs.billingContainerTable.style.width = "";
              this.$refs.billingContainerOuterTable.style.width = "";
              this.fireError();
              this.$forceUpdate();
            })
            .catch(() => {
              this.$refs.billingContainerTable.style.width = "";
              this.$refs.billingContainerOuterTable.style.width = "";
              this.fireError();
              this.$forceUpdate();
              Swal.fire({
                title: "Error",
                html: "Something went wrong...",
                icon: "error",
              });
            });
        }, 0);
      });
    },
    sortAlphabetically(arrayInput) {
      const arraySrc = new Array(...arrayInput);
      const result = arraySrc.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    getAptProvider: function (item) {
      let tempItem;
      if (item.provider) {
        tempItem = item.provider;
      } else {
        tempItem = item.user_1 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getAptClient: function (item) {
      let tempItem;
      if (item.patient) {
        tempItem = item.patient;
      } else {
        tempItem = item.user_2 || {};
        tempItem.user_id = tempItem.id;
      }
      return tempItem;
    },
    getAptExpenses: function (item) {
      if (item && item.expenses) {
        return item.expenses;
      }
      return 0;
    },
    generateInvoice: function () {
      if (this.busy) {
        return;
      }
      this.loadRestOfData().then(() => {
        this.fireError();
        const invoicePromises = [];
        this.invoiceErrors = [];
        // Get the currently selected user, error is All Clients
        let currentUser = this.clientsList.filter((row) => {
          return row.user_id == this.submittedFilter.clients;
        });

        if (currentUser[0]) {
          currentUser = currentUser[0];
          invoicePromises.push(this.generateInvoicePromise(currentUser));
          this.processing = true;
          this.setProgressMessage(
            "Generating invoices...<br>This may take few moments. Please don't refresh the page."
          );
        } else {
          this.clientsList.forEach((client, index) => {
            this.processing = true;
            this.percent = 0;
            if (index == 0) {
              invoicePromises.push(this.generateInvoicePromise(client));
              this.setProgressMessage(
                "Generating invoices...<br>This may take few moments. Please don't refresh the page."
              );
            } else {
              invoicePromises[index] = invoicePromises[index - 1].then(() => {
                this.percent = Math.ceil(
                  (index / this.clientsList.length) * 100
                );
                this.setProgressMessage(
                  "Generating invoices...<br>This may take few moments. Please don't refresh the page."
                );
                return this.generateInvoicePromise(client);
              });
            }
          });
        }
        if (invoicePromises.length) {
          invoicePromises[invoicePromises.length - 1].then(() => {
            this.fireError();
            if (this.invoiceErrors.length) {
              const invoiceSwalError = `<b>Warning while generating invoices:</b><br><br><ul class='text-left'><li class='h5 border-bottom pb-2'>${this.invoiceErrors.join(
                "</li><li class='h5 border-bottom pb-2'>"
              )}</li></ul>`;
              Swal.fire({
                title: "",
                html: invoiceSwalError,
                icon: "warning",
              });
            } else {
              Swal.fire({
                title: "Invoice Generated",
                text: "To view invoice see client profile under Invoices tab",
                icon: "success",
              });
            }
          });
        }
      });
    },
    generateInvoicePromise: function (currentUser) {
      //Retrieves all service invoices based on user
      const startDateTime = `${dateFormat(
        this.submittedFilter.range.start,
        "YYYY-MM-DD"
      )}T00:00:00.000000Z`;
      const endDateTime = `${dateFormat(
        this.submittedFilter.range.end,
        "YYYY-MM-DD"
      )}T23:59:59.000000Z`;

      const canadaOffsetStart = dayjs
        .tz(new Date(startDateTime), "America/Toronto")
        .utcOffset();
      const canadaOffsetEnd = dayjs
        .tz(new Date(endDateTime), "America/Toronto")
        .utcOffset();
      const payload = {};
      payload.start = dayjs(startDateTime)
        .add(canadaOffsetStart, "minute")
        .utc()
        .format();

      payload.end = dayjs(endDateTime)
        .add(canadaOffsetEnd, "minute")
        .utc()
        .format();

      return this.getServiceInvoice({
        id: currentUser.user_id,
        ...payload,
      }).then((billings) => {
        return this.handleGenerateInvoice(billings, currentUser);
      });
    },
    handleGenerateInvoice: function (billings, currentUser) {
      billings = billings || [];

      if (billings.length > 0) {
        // Check for valid address
        const currentPatient = this.invoiceClients.filter(
          (item) => item.user_id == currentUser.user_id
        )[0];
        if (((currentPatient || {}).addresses || []).length > 0) {
          // Format data and invoke invoice creation method
          let subTotal = 0;
          let taxTotal = 0;
          let total = 0;
          billings.forEach((row) => {
            if (
              row.service &&
              (row.service.unit == "FT" || row.service.unit == "FLAT")
            ) {
              row.qty = 1;
            }

            let subtotal = row.qty * row.rate;
            if (
              row.appointment.expenses != null &&
              row.service &&
              row.service.parent_service_id == null &&
              row.appointment.expenses
            ) {
              subtotal += row.appointment.expenses;
            }
            subTotal += subtotal;
            taxTotal += row.tax;
            total += subtotal;
          });

          let invoiceList = billings
            .map((row) => {
              return row.invoice_id;
            })
            .join("-");

          //Creates a new invoice
          return this.createInvoice({
            id: currentUser.user_id,
            data: {
              subTotal: subTotal,
              tax: taxTotal,
              total: total,
              invoice_list: invoiceList,
            },
          }).then(() => {
            return Promise.resolve();
          });
        } else {
          this.invoiceErrors.push(
            `Client (${currentUser.name}) does not have a registered address. Please register address in the clients profile.`
          );
          return Promise.resolve();
        }
      } else {
        this.invoiceErrors.push(
          `No data available for Client (${currentUser.name}) to generate invoice.`
        );
        return Promise.resolve();
      }
    },
    setProgressMessage: function (message) {
      const percentMessage = `<h1 style="color: mediumspringgreen">${this.percent} %</h1>`;
      this.processingText = `${
        this.percent > -1 ? percentMessage : ""
      }${message}`;
    },
    fireError: function () {
      this.processing = false;
      this.processingText = "";
      this.percent = -1;
    },
    formatToQuickbookCSV: function () {
      if (this.qbCsvData) {
        let data = this.qbCsvData;
        data = data.filter(
          (row) =>
            this.getAptClient(row.appointment) &&
            typeof row.appointment != "string" &&
            !row.totalRow
        );
        let formattedCSV = data.map((row) => {
          let invoiceDate = new Date();
          let dueDate = new Date(invoiceDate);
          dueDate.setMonth(dueDate.getMonth() + 1);
          return {
            invoiceNo: row.invoice_id || "-------",
            customer: row.client ? row.client.name || "" : "",
            invoiceDate: this.prettyDate(invoiceDate, "MM/DD/YYYY"),
            dueDate:
              data.appointment != "total"
                ? this.prettyDate(dueDate, "MM/DD/YYYY")
                : "",
            terms: "",
            serviceDate:
              row.appointment && row.appointment.end_time
                ? this.prettyDate(row.appointment.end_time, "MM/DD/YYYY")
                : "-------",
            location: "",
            memo:
              row.appointment && row.appointment.memo
                ? row.appointment.memo
                : "",
            item: row.service && row.service.name ? row.service.name : "",
            itemDescription:
              row.service && row.service.description
                ? row.service.description
                : "",
            qty: row.qty,
            hours: row.hours || "",
            flat: row.flat || "",
            mileage: row.mileage || "",
            rate: row.client_rate || "",
            amount: row.amt || "",
            subtotal: row.subtotal || "",
            taxCode: "HST",
            taxAmount: row.totalWithHst || "",
          };
        });
        this.printCSVData = formattedCSV;
      }
    },
    getInvoiceClientById: function (id) {
      return this.invoiceClients.filter((item) => item.user_id == id)[0] || {};
    },
    loadMore() {
      if (this.billings.length < this.total) {
        this.busy = true;
        this.getReportByFilter(this.current + 1).then(() => {
          this.busy = false;
        });
      }
    },
    loadRestOfData(fromViewReport = false) {
      return this.loadRestOfDataAsync(fromViewReport).then(() => {
        this.isCancelled = false;
      });
    },
    async loadRestOfDataAsync(fromViewReport = false) {
      const leftCount = this.total - this.billings.length;
      if (leftCount == 0) {
        return Promise.resolve();
      } else {
        let numberOfRequests = parseInt(leftCount / this.limit);
        if (leftCount % this.limit > 0) {
          numberOfRequests++;
        }
        this.busy = true;
        if (!fromViewReport) {
          this.processing = true;
          this.setProgressMessage(
            "Getting all data...<br>This may take few moments. Please don't refresh the page."
          );
        }
        this.percent = 0;
        const requestEachTime = 5;
        let numberOfRequestGroup = parseInt(numberOfRequests / requestEachTime);
        numberOfRequestGroup += numberOfRequests % requestEachTime ? 1 : 0;
        let currentPage = this.current + 1;
        for (let i = 1; i <= numberOfRequestGroup; i++) {
          if (this._isDestroyed || this.isCancelled) {
            if (this.isCancelled) {
              this.setBillingCurrentPage(currentPage - 1);
            }
            break;
          }
          await new Promise((resolve) => {
            const invoicePromisees = [];
            for (
              let j = 0;
              j < requestEachTime &&
              j + (i - 1) * requestEachTime < numberOfRequests;
              j++
            ) {
              invoicePromisees.push(this.getReportByFilter(currentPage + j));
            }
            currentPage += requestEachTime;
            Promise.all(invoicePromisees).then(() => {
              this.percent = +parseFloat(
                ((i * requestEachTime * this.limit) / this.total) * 100
              ).toFixed(2);
              if (!this.fromViewReport) {
                this.setProgressMessage(
                  "Getting all data...<br>This may take few moments. Please don't refresh the page."
                );
              }
              return resolve();
            });
          });
        }
        if (this.billings.length == this.total) {
          this.percent = 0;
        }
        this.busy = false;
        this.processing = false;
        return Promise.resolve();
      }
    },
    generateQuickbooksCsv() {
      if (this.busy) {
        return;
      }
      this.loadRestOfData().then(() => {
        this.$refs.quickbook.click();
      });
    },
    submitHCAIReport() {
      this.submittingHCAIReport = true;
      this.loadRestOfData().then(() => {
        const dataToSend = [];
        if (!this.printExcelData.length) {
          return;
        }
        this.printExcelData.forEach((item) => {
          dataToSend.push({
            "Billing ID": item.id,
            "Client First": `${
              this.getAptClient(item.appointment).name || ""
            }`.split(", ")[1],
            "Client Last": `${
              this.getAptClient(item.appointment).name || ""
            }`.split(", ")[0],
            "Claim Number": `${(
              this.ClientClaimNumber(
                this.getAptClient(item.appointment).user_id
              )
                .toString()
                .match(/.{1,28}/g) || []
            ).join("")}`,
            "Policy Number": `${(
              this.ClientPolicyNumber(
                this.getAptClient(item.appointment).user_id
              )
                .toString()
                .match(/.{1,28}/g) || []
            ).join("")}`,
            DOB: `${
              this.ClientBirthDate(
                this.getAptClient(item.appointment).user_id
              ) || ""
            }`,
            ID: item.clientId,
            "Provider First": `${
              this.getAptProvider(item.appointment).name || ""
            }`.split(", ")[1],
            "Provider Last": `${
              this.getAptProvider(item.appointment).name || ""
            }`.split(", ")[0],
            "Registration Number": `${(
              (item.college_number || "").toString().match(/.{1,28}/g) || []
            ).join("")}`,
            "HCAI Registration ID": `${(
              (this.getAptProvider(item.appointment).hcai_registration_id || "")
                .toString()
                .match(/.{1,28}/g) || []
            ).join("")}`,
            "Provider Profession":
              this.getHCAIProfessionName(item.service.id) ||
              this.getProfessionName(item.service.id),
            "Service Code": `${item.service.code || ""}`,
            "Service Name": `${item.service.name || ""}`,
            MVA: "y",
            CAT: `${
              this.ClientHcaiCat(this.getAptClient(item.appointment).user_id) ||
              ""
            }`,
            "Visit Date": this.prettyDate(
              this.adjustDate(item.appointment.start_time),
              "MM/DD/YYYY"
            ),
            MEMO: item.appointment.memo || "-",
            Rate: isNaN(+parseFloat(item.client_rate))
              ? "-----------"
              : `${+parseFloat(item.client_rate).toFixed(2)}`.replace(
                  /(\d)(?=(\d{3})+(?!\d))/g,
                  "$1,"
                ),
            QTY: `${+parseFloat(item.qty).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            ),
            Measure: `${item.service.unit || ""}`,
            AMT: `${+parseFloat(item.amt).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            ),
            Tax: `${+parseFloat(item.calculated_tax).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            ),
            Expenses: `${+parseFloat(item.expenses).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            ),
            "Sub Total": `${+parseFloat(
              `${item.client_subTotal}`.replace(/,/g, "")
            ).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
            Total: `${+parseFloat(item.totalWithHst).toFixed(2)}`.replace(
              /(\d)(?=(\d{3})+(?!\d))/g,
              "$1,"
            ),
          });
        });
        this.sendHCAIReport({ report_data: dataToSend }).then((success) => {
          this.submittingHCAIReport = false;
          if (success) {
            Swal.fire({
              title: "Success",
              text: "HCAI Report Sent Successfully",
              icon: "success",
            });
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@media print {
  @page {
    size: auto;
  }
  body {
    background-color: #fff !important;
  }
  .reportTable {
    table-layout: fixed;
  }
  .printPage {
    page-break-after: always;
    margin-top: 120px;
  } /* printPage-break-after works, as well */
}
.disabled {
  pointer-events: none;
}
:disabled {
  pointer-events: none;
}

.border-bottom {
  border-color: var(--secondary-color) !important;
}
.like-header td {
  background-color: rgba(37, 37, 37, 0.4) !important;
  color: white !important;
  font-weight: 900 !important;
  font-size: 0.8125rem !important;
}
.mobile-tablet-loadmore {
  background-color: #6e84a3 !important;
  border-color: #6e84a3;
  color: #fff !important;
}
.action-indicator {
  width: 12px;
  height: 12px;
  padding: 0;
  position: absolute;
  top: 0px;
  right: 1px;
}
.pdfCompanyLogo {
  margin: 50px auto 20px auto;
  text-align: center;
  img {
    height: 200px;
  }
}
.reportCompanyLogo {
  margin: 50px auto;
  text-align: center;
  img {
    height: 200px;
  }
}
.signitureReport {
  img {
    height: 50px;
    width: 200px;
  }
}
.print-menu {
  max-height: 350px;
  overflow-y: auto;
  z-index: 1000;
  .dropdown-item.disabled {
    opacity: 0.6;
    &,
    input,
    label {
      pointer-events: none;
      &::before,
      &::after {
        pointer-events: none;
      }
    }
  }
}
.loader {
  top: 0;
  left: 0;
  right: 0;
  border: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.6);
  color: whitesmoke;
  flex-direction: column;
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .report-actions-container {
    flex-direction: column-reverse;

    .report-actions-inner-container {
      margin-top: 36px;

      .view-report {
        min-width: 50%;
        margin-bottom: 12px;
      }
    }
  }
}
.multiselect {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  border-radius: 36px;
}
.py-8-px {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
<style lang="scss">
.group-by-select {
  select {
    width: auto;
    padding-left: 8px;
    padding-right: 8px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
  }
  label {
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: 100% !important;
  }
}
.excel-control-group {
  .excel-menu {
    left: -42px !important;
  }
}
.class-ddl,
.profession-ddl,
.service-ddl {
  .multiselect__tags {
    min-height: 40.5px;
  }
}
</style>
